//产品详情弹窗 入库信息列表配置
export const inventoryListTitleConfig = [
  [
    {
      key:"unit",
      prop:"unit",
        label: '单位',
        className: 'table_text2',
    },
    {
      key:"unit",
      prop:"unit",
      label:'规格/型号'
    },
    {
      key:"unit",
      prop:"unit",
      label:'库存数量'
    },
    {
      key:"unit",
      prop:"unit",
      label:'单价'
    },
    {
      key:"unit",
      prop:"unit",
      label:'合计金额'
    },
  ],
  [
    {
      key:"unit",
      prop:"unit",
      label:'时间'
    },
    {
      key:"unit",
      prop:"unit",
      label:'单位'
    },
    {
      key:"unit",
      prop:"unit",
      label:'规格/型号'
    },
    {
      key:"unit",
      prop:"unit",
      label:'数量'
    },
    {
      key:"unit",
      prop:"unit",
      label:'单价'
    },
    {
      key:"unit",
      prop:"unit",
      label:'合计金额'
    },
  ],
  [
    {
      key:"unit",
      prop:"unit",
      label:'时间'
    },
    {
      key:"unit",
      prop:"unit",
      label:'单位'
    },
    {
      key:"unit",
      prop:"unit",
      label:'规格/型号'
    },
    {
      key:"unit",
      prop:"unit",
      label:'数量'
    },
    {
      key:"unit",
      prop:"unit",
      label:'单价'
    },
    {
      key:"unit",
      prop:"unit",
      label:'合计金额'
    },
  ],
]


export const optionConfig = (num)=>{
  let str = '';
  switch (num) {
    case 1:
      str = '选项一'
      break;
    case 2:
      str = '选项二'
      break;
    case 3:
      str = '选项三'
      break;
    case 4:
      str = '选项四'
      break;
    case 5:
      str = '选项五'
      break;
    case 6:
      str = '选项六'
      break;
    case 7:
      str = '选项七'
      break;
    case 8:
      str = '选项八'
      break;
    case 9:
      str = '选项九'
      break;
    case 10:
      str = '选项十'
      break;

    default:
      break;
  }
  return str
}

export const options = [
  {
    value: 'guide',
    label: 'Guide',
    children: [
      {
        value: 'disciplines',
        label: 'Disciplines',
        children: [
          {
            value: 'consistency',
            label: 'Consistency',
          },
          {
            value: 'feedback',
            label: 'Feedback',
          },
          {
            value: 'efficiency',
            label: 'Efficiency',
          },
          {
            value: 'controllability',
            label: 'Controllability',
          },
        ],
      },
      {
        value: 'navigation',
        label: 'Navigation',
        children: [
          {
            value: 'side nav',
            label: 'Side Navigation',
          },
          {
            value: 'top nav',
            label: 'Top Navigation',
          },
        ],
      },
    ],
  },
  {
    value: 'component',
    label: 'Component',
    children: [
      {
        value: 'basic',
        label: 'Basic',
        children: [
          {
            value: 'layout',
            label: 'Layout',
          },
          {
            value: 'color',
            label: 'Color',
          },
          {
            value: 'typography',
            label: 'Typography',
          },
          {
            value: 'icon',
            label: 'Icon',
          },
          {
            value: 'button',
            label: 'Button',
          },
        ],
      },
      {
        value: 'form',
        label: 'Form',
        children: [
          {
            value: 'radio',
            label: 'Radio',
          },
          {
            value: 'checkbox',
            label: 'Checkbox',
          },
          {
            value: 'input',
            label: 'Input',
          },
          {
            value: 'input-number',
            label: 'InputNumber',
          },
          {
            value: 'select',
            label: 'Select',
          },
          {
            value: 'cascader',
            label: 'Cascader',
          },
          {
            value: 'switch',
            label: 'Switch',
          },
          {
            value: 'slider',
            label: 'Slider',
          },
          {
            value: 'time-picker',
            label: 'TimePicker',
          },
          {
            value: 'date-picker',
            label: 'DatePicker',
          },
          {
            value: 'datetime-picker',
            label: 'DateTimePicker',
          },
          {
            value: 'upload',
            label: 'Upload',
          },
          {
            value: 'rate',
            label: 'Rate',
          },
          {
            value: 'form',
            label: 'Form',
          },
        ],
      },
      {
        value: 'data',
        label: 'Data',
        children: [
          {
            value: 'table',
            label: 'Table',
          },
          {
            value: 'tag',
            label: 'Tag',
          },
          {
            value: 'progress',
            label: 'Progress',
          },
          {
            value: 'tree',
            label: 'Tree',
          },
          {
            value: 'pagination',
            label: 'Pagination',
          },
          {
            value: 'badge',
            label: 'Badge',
          },
        ],
      },
      {
        value: 'notice',
        label: 'Notice',
        children: [
          {
            value: 'alert',
            label: 'Alert',
          },
          {
            value: 'loading',
            label: 'Loading',
          },
          {
            value: 'message',
            label: 'Message',
          },
          {
            value: 'message-box',
            label: 'MessageBox',
          },
          {
            value: 'notification',
            label: 'Notification',
          },
        ],
      },
      {
        value: 'navigation',
        label: 'Navigation',
        children: [
          {
            value: 'menu',
            label: 'Menu',
          },
          {
            value: 'tabs',
            label: 'Tabs',
          },
          {
            value: 'breadcrumb',
            label: 'Breadcrumb',
          },
          {
            value: 'dropdown',
            label: 'Dropdown',
          },
          {
            value: 'steps',
            label: 'Steps',
          },
        ],
      },
      {
        value: 'others',
        label: 'Others',
        children: [
          {
            value: 'dialog',
            label: 'Dialog',
          },
          {
            value: 'tooltip',
            label: 'Tooltip',
          },
          {
            value: 'popover',
            label: 'Popover',
          },
          {
            value: 'card',
            label: 'Card',
          },
          {
            value: 'carousel',
            label: 'Carousel',
          },
          {
            value: 'collapse',
            label: 'Collapse',
          },
        ],
      },
    ],
  },
  {
    value: 'resource',
    label: 'Resource',
    children: [
      {
        value: 'axure',
        label: 'Axure Components',
      },
      {
        value: 'sketch',
        label: 'Sketch Templates',
      },
      {
        value: 'docs',
        label: 'Design Documentation',
      },
    ],
  },
]

export const upLoadTableConfig = [
  {
    title: "产品名称",
    fieldKey: "productName",
    className: 'table_text2'
  },
  {
    title: "简称",
    fieldKey: "subName",
    className: 'table_text'
  },
  {
    title: "产品条码",
    fieldKey: "encoded",
    className: 'table_text'
  },
  {
    title: "规格/型号",
    fieldKey: "model",
    className: 'table_text2'
  },
  {
    title: "产品单位",
    fieldKey: "unitName",
    className: 'table_text2'
  },
  {
    title: "产品分类",
    fieldKey: "classifyName",
    className: 'table_text'
  },
  {
    title: "产品品牌",
    fieldKey: "brandName",
    className: 'table_text'
  },
]