<template>
  <div class="down-sample cur_p" v-bind="attrs">
    <img src="@img/product/excel.png" alt="位元灵感" class="pic"/>
    <div class="text">文件模板下载</div>
  </div>
</template>

<script setup>
import {useAttrs} from "vue";

const attrs = useAttrs()
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'DownSample',
})
</script>

<style lang="scss" scoped>
.down-sample {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 36px;
  line-height: 36px;
  background-color: $bgColor2;
  border-radius: $borderRadius8;

  .pic {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  .text {
    font-size: 14px;
    color: $fsColor4;
  }
}
</style>