<template>
  <el-popover
      placement="right"
      trigger="click"
      :content="content"
      popper-style="border-radius: 8px;color:#797A80;font-size: 12px"
  >
    <template #reference>
      <img src="@img/shop/index/konw-more.png" alt="位元灵感" class="konw-more-img"/>
    </template>
  </el-popover>
</template>

<script setup>
import {getCurrentInstance, toRefs} from "vue";

const props = defineProps({
  content: {
    type: String,
    default: ''
  }
})

const {content} = toRefs(props)
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "knowMore",
});
</script>

<style lang="scss" scoped>
.konw-more-img {
  width: 14px;
  height: 14px;
  cursor: pointer;
  vertical-align: middle;
}

</style>
