/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-31 14:34:44
 * @FilePath: /aurora-carton-web/src/components/goods/goodsInfoModal/util.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {customTemplate, productInfo} from "@/utils/api/product/product.js";
import {reactiveAssign} from "@/utils/tool.js";

//获取产品详情
export const getGoodsDetails =async (e,callBack=()=>{})=>{
    //获取模板信息
    const getTemplate = async () => {
        let res = await customTemplate();
        if (res.code != 0) {
            return;
        }
        let list = res.data.list || [];
        return list
    };
  let goodsInfo ={}
  let res = await  productInfo({
    productId:e.productId
  })
  if(res.code != 0){
    return false
  }
  if(!res.data?.img){
    res.data.img = 'https://oss.musaemotion.com/WEB/assets/img/index/productDefaultImg.png'
  }
  reactiveAssign(goodsInfo,res.data)
  getTemplate().then((res)=>{
    goodsInfo.list = []
      res.forEach((item)=>{
      goodsInfo.list.push({
        title:item.title,
        value:goodsInfo[item.fieldKey],
        fieldKey:item.fieldKey
      })
      })
      callBack(goodsInfo)
    })
  }
