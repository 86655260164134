import {ref} from 'vue';
import {productPrice} from '@/utils/api/product/product'

export const useProductPrice = () => {
    const productPriceList = ref([])
    const getProductPriceList = async (goodsInfo) => {
        try {
            const {data} = await productPrice(goodsInfo)
            productPriceList.value = data?.list || []
        } catch (e) {
        }
    }
    return {
        productPriceList,
        getProductPriceList
    }
}