<template>
  <el-dialog
      v-model="showUpLoad"
      title="导入"
      width="1400"
      class="upload-goods-dialog"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @open="openUpload"
      @close="closeUpload"
  >
    <div class="upload-goods-dialog" v-loading="loading">
      <div class="diaTitle">
        <div class="title">导入文件</div>
        <div class="btnBox">
          <down-sample @click="clickDownSample"/>
        </div>
      </div>
      <el-upload
          ref="uploadRef"
          v-model:file-list="fileList"
          class="upload-xlsx"
          accept=".xls,.xlsx"
          :http-request="upLoadFile"
          :on-exceed="handleExceed"
          :limit="1"
      >
        <template #file="{ file }">
          <div class="file-content">
            <img src="@img/product/big-excel.png" alt="位元灵感" class="pic">
            <div class="file-info">
              <show-more class="file-name" :text="file.name"></show-more>
              <div class="file-size">{{ formatFileSize(file.size) }}</div>
            </div>
            <div class="file-status" v-if="!loading">
              <el-icon class="success" v-if="!isErr">
                <SuccessFilled/>
              </el-icon>
              <el-icon class="err" v-else>
                <CircleCloseFilled/>
              </el-icon>
              <div class="text">{{ !isErr ? '导入成功' : '导入失败' }}</div>
            </div>
            <div class="file-status" v-else>

              <div class="text">导入中</div>
            </div>
          </div>
        </template>
        <template #trigger>
          <el-button class="export-btn" :class="fileData.name?'ml_24':''">
            <img src="@img/product/upLoad.png" alt="位元灵感" class="up-pic"/>{{ btnText }}
          </el-button>
        </template>
      </el-upload>
      <div class="diaTitle mt_20">
        <div class="title">校验预览</div>
        <div class="result-text" v-if="statistics.all">
            <span class="text">共导入数据</span>
          <span class="all">{{ statistics.all }}条，</span>
            <span class="text">写入成功</span>
          <span class="success ">{{ statistics.success }}条，</span>
            <span class="text ">写入失败</span>
          <span class="err">{{ statistics.err }}条。</span>
        </div>
      </div>
      <div class="mt_16">
        <template v-if="!noData">
          <div class="tab">
            <el-tabs v-model="tabChecked" class="demo-tabs" @tab-change="tabCheck">
              <el-tab-pane :label="`写入成功（${statistics.success}）`" name="success">
              </el-tab-pane>
              <el-tab-pane :label="`写入失败（${statistics.err}）`" name="err">
              </el-tab-pane>
            </el-tabs>
            <el-button class="down-btn" type="primary" v-if="errList.length" @click="clickDownResult">下载结果文件
            </el-button>
          </div>
          <div class="tabList">
            <zh-table :data="tableData">
              <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                  align="center"
                  fixed
              />
              <template v-for="item in upLoadTableConfig" :key="item.fieldKey">
                <el-table-column
                    v-if="item.fieldKey === 'encoded' || item.fieldKey === 'model'"
                    align="center"
                    :prop="item.fieldKey"
                    :label="item.title"
                    :width="item.width || 'auto'"
                    :class-name="item.className"
                >
                  <template #default="scope">
                    <more-list :list='scope.row[item.fieldKey]'></more-list>
                  </template>
                </el-table-column>
                <el-table-column
                    v-else
                    align="center"
                    :prop="item.fieldKey"
                    :label="item.title"
                    :width="item.width || 'auto'"
                    :class-name="item.className"
                    show-overflow-tooltip
                >
                </el-table-column>
              </template>
              <el-table-column
                  v-if="tabChecked === 'err'"
                  align="center"
                  prop="remark"
                  label="备注"
                  show-overflow-tooltip
                  class-name="red"
              >
              </el-table-column>
            </zh-table>
          </div>
        </template>
        <div class=" zh_empty" v-else></div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import MoreList from "@components/moreList/moreList.vue";
import {computed, getCurrentInstance, ref} from 'vue'
import DownSample from "@components/common/downSample/index.vue";
import {uploadFile} from "@/utils/api/product/product";
import {formatFileSize} from "@utils/tool";
import {upLoadTableConfig} from '../config'
import {customTemplate} from "@utils/api/product/product";
import ShowMore from "@components/common/showMore/index.vue";
import ZhTable from "@components/element/table.vue";
import ExportNew from '@utils/excel/export.js'
import {useLoading} from "@utils/tool/loading";
import {genFileId} from "element-plus"
import {cloneDeep} from "lodash";

const emit = defineEmits(['closeUpload'])
const {loading, setLoading} = useLoading()
const {proxy} = getCurrentInstance()
const tabChecked = ref('success')
const showUpLoad = ref(false)
const uploadRef = ref(null)
const tableData = ref([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}])
const successList = ref([])
const errList = ref([])
const isErr = ref(false)

const noData = computed(() => {
  return !successList.value.length && !errList.value.length
})

const fileData = ref({
  name: ''
})
const statistics = computed(() => {
  return {
    all: successList.value.length + errList.value.length,
    success: successList.value.length,
    err: errList.value.length,
  }
})


const tabCheck = (e) => {
  switch (tabChecked.value) {
    case 'success':
      tableData.value = successList.value || []
      break;
    case 'err':
      tableData.value = errList.value || []
      break;
  }
}
const btnText = computed(() => {
  return fileData.value?.name ? '重新上传' : '上传文件'
})

const fileList = ref([])

// 打开上传弹窗
const openUpload = () => {
}

const closeUpload = () => {
  uploadRef.value.clearFiles()
  reset()
  emit('closeUpload')
}

const upLoadFile = (data) => {
  const file = data.file
  if (!file) return
  const formData = new FormData()
  formData.append('file', file)
  fileData.value = file
  setLoading(true)
  return uploadFile(formData).then(res => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      isErr.value = true
      return
    }
    successList.value = res.data?.successList || []
    errList.value = res.data?.errorList || []
    if (successList.value.length) {
      tableData.value = successList.value
      tabChecked.value = 'success'
      return;
    }
    if (errList.value.length) {
      tableData.value = errList.value
      tabChecked.value = 'err'
    }
  }).finally(() => {
    setLoading(false)
  })
}

function reset() {
  isErr.value = false
  tableData.value = []
  successList.value = []
  errList.value = []
  fileData.value = {
    name: ''
  }
  tabChecked.value = 'success'
}

const handleExceed = (files) => {
  reset()
  uploadRef.value.clearFiles()
  const file = files[0]
  file.uid = genFileId()
  uploadRef.value.handleStart(file)
  upLoadFile({file: file})
};

//下载模板
const clickDownSample = () => {
  window.open('https://oss.musaemotion.com/WEB/assets/xlsx/%E6%96%87%E4%BB%B6%E6%A8%A1%E6%9D%BF.xlsx')

}

const getTem = (callBack) => {
  customTemplate().then(res => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    let titleList = res.data?.list || []
    titleList.forEach(item => {
      if (item.required) item.title = item.title + '（必填）'
    })

    titleList.unshift({
      title: '序号',
      fieldKey: 'index',
      sort: 0
    })
    //过滤titleList里面fieldKey为包含param的字段
    titleList = titleList.filter(item => {
      return !item.fieldKey.includes('param') && item.fieldKey !== 'img'
    })
    callBack(titleList)
  })
}

//下载结果文件
const clickDownResult = () => {
  getTem((titleList) => {
    let list = cloneDeep(errList.value)
    list.forEach((item, index) => {
      if (item.encoded?.length) item.encoded = item.encoded.join(',')
      if (item.model?.length) item.model = item.model.map(i => i.modelName).join(',')
      item.index = index + 1
      item.isErr = true
    })
    titleList.push({
      title: '备注',
      fieldKey: 'remark',
      sort: 99999
    })

    ExportNew.export2Excel(list, titleList, '写入失败文件', true)
  })
}

defineExpose({
  showUpLoad,
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'UpLoadGoods',
})
</script>

<style lang="scss" src="./index.scss" scoped></style>