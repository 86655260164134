<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
    v-model="showDia"
    title="新增产品"
    width="920"
    class="addGoodsNew"
    :show-close="false"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
  >
       <div class="content">
        <div class="error_tips animate__animated animate__zoomIn" v-show="isRepeat">
          <img src="../../../../assets/img/product/error.png" class="pic" />
          <div class="error_text">数据标题不允许重复，</div>
          <div class="error_sub_text">即产品名称-规格型号-产品品牌-单位不允许重复</div>
        </div>
      <div class="diaTitle">
        <div class="title">基础信息</div>
        <div class="btnBox">
          <el-button class="btn" @click="handleClickUpdate">自定义显示字段</el-button>
        </div>
      </div>
      <div class="query">
        <div class="item" v-for="(item, index) in typeList" :key="index">
          <div v-if="item.type == 1">
            <div class="name">
              <span class="red" v-if="item.required">*</span>产品名称
            </div>
            <div class="input">
              <el-input
              v-elInput
                class="queryInput"
                placeholder="请输入产品名称"
                v-model.trim="addInfo.productName"
                :maxlength="30"
              ></el-input>
            </div>
          </div>
          <div v-if="item.type == 2">
            <div class="name">
              <span class="red" v-if="item.required">*</span>产品单位
            </div>
              <div class="input">
              <SelectByAbcModal placeholder='产品单位' typeName="unit" add update :maxNum="1" v-model:list="addInfo.unit" className="queryInput" @change="getUnit"></SelectByAbcModal>
            </div>
          </div>
          <div v-if="item.type == 3">
            <div class="name modelList">
              <div class="add_title">
                <span class="red" v-if="item.required">*</span>规格/型号
              </div>
              <ModelInput v-model="modelList" :unit="addInfo.unit?.[0]?.unitName"></ModelInput>
            </div>
           <ModelList v-model="modelList"></ModelList>
          </div>
          <div v-if="item.type == 4">
            <div class="name">
              <span class="red" v-if="item.required">*</span>产品分类
            </div>
            <div class="input">
              <el-cascader
                v-model="addInfo.classifyId"
                :options="typeOptions"
                :props="candiyConfig"
                placeholder="产品分类"
                class="queryInput"
                style="width: 360px"
                filterable
              />
            </div>
          </div>
          <div v-if="item.type == 5">
            <div class="name">
              <span class="red" v-if="item.required">*</span>产品简称
            </div>
            <div class="input">
              <el-input
              v-elInput
                class="queryInput"
                placeholder="请输入产品简称"
                v-model.trim="addInfo.subName"
                :maxlength="30"
              ></el-input>
            </div>
          </div>
          <div v-if="item.type == 6">
            <div class="name">
              <span class="red" v-if="item.required">*</span>产品品牌
            </div>
            <div class="input">
              <SelectByAbcModal placeholder='产品品牌' typeName="brand" add update :maxNum="1" v-model:list="addInfo.brand" className="queryInput" @change="getBrand"></SelectByAbcModal>
            </div>
          </div>
          <div v-if="item.type == 7">
            <div class="name">
              <span class="red" v-if="item.required">*</span>其他描述
            </div>
            <div class="input">
              <el-input
              v-elInput
                class="queryInput"
                placeholder="请输入其他描述"
                v-model="addInfo.remark"
              ></el-input>
            </div>
          </div>
          <div v-if="item.type == 9">
            <div class="name barcodeList">
              <div class="add_title">
                 <span class="red" v-if="item.required">*</span>产品条码
              </div>
              <el-radio-group
                v-model="addInfo.hasEncoded"
                style="margin-left: auto; font-size: 16px;height:14px"
              >
              <el-radio :label="true">有条码</el-radio>
                <el-radio :label="false">无条码</el-radio>
              </el-radio-group>
            </div>
            <div class="input add_code_input">
              <form class="add_code_content" @submit.prevent="addBarcode">
              <el-input
              v-elInput
                class="queryInput addInput"
                placeholder="请输入产品条码"
                v-model.trim="barcode"
                :maxlength="26"
                :disabled="!addInfo.hasEncoded"
              ></el-input>
              <div class="addBtn animate__animated animate__zoomIn" v-show='addInfo.hasEncoded'  @click="addBarcode">添加</div>
            </form>
              <div class="add_code_list animate__animated animate__zoomIn" :class="barcodesList.length > 0 && addInfo.hasEncoded?'active':''">
                <el-scrollbar>
                  <el-tag
                  v-for="tag in barcodesList" :key="tag"
                    closable
                    @close="delBarcode(tag)"
                    style="margin: 8px"
                    type="info"
                    effect="plain"
                  >
                  {{ tag }}
                </el-tag>
                </el-scrollbar>
              </div>
              </div>
            </div>
          <div v-if="item.type == 10">
            <div class="name">
              <span class="red" v-if="item.required">*</span
              ><span>商品图片</span>
            </div>
            <div class="input" >
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                accept="image/png, image/jpeg,image/jpg"

              >
                <div class="imgBox"  v-if="addInfo.img" v-loading='loading'>
                  <img :src="addInfo.img" class="avatar" />
                  <el-icon  class="icon-close" @click.stop='handleRemoveImg'><Close /></el-icon>
                </div>
                <div  v-else class="imgBox" v-loading='loading'>
                  <img src="../../../../assets/img/product/upImg.png" class="avatar" />
                </div>
              </el-upload>
            </div>
          </div>
          <div v-if="item.type == 11">
            <div class="name">
              <span class="red" v-if="item.required">*</span
              ><span>{{ item.title }}</span>
            </div>
            <div class="input">
              <el-input
              v-elInput
                class="queryInput"
                :placeholder="item.tableValue"
                v-model="addInfo[item.fieldKey]"
              ></el-input>
            </div>
          </div>
          <div v-if="item.type == 12">
            <div class="name">
              <span class="red" v-if="item.required">*</span
              ><span>{{ item.title }}</span>
            </div>
            <div class="input">
              <el-radio-group v-model="addInfo[item.fieldKey]">
                <el-radio
                  :label="item"
                  size="large"
                  v-for="(item, index) in item.tableValue"
                  :key="index"
                  >{{ item }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <div v-if="item.type == 13">
            <div class="name">
              <span class="red" v-if="item.required">*</span
              ><span>{{ item.title }}</span>
            </div>
            <div class="input">
              <el-checkbox-group v-model="addInfo[item.fieldKey]">
                <el-checkbox
                  :label="item"
                  v-for="(item, index) in item.tableValue"
                  :key="index"
                />
              </el-checkbox-group>
            </div>
          </div>
          <div v-if="item.type == 14">
            <div class="name">
              <span class="red" v-if="item.required">*</span
              ><span>{{ item.title }}</span>
            </div>
            <div class="input">
              <el-select class="queryInput" v-model="addInfo[item.fieldKey]">
                <el-option
                  v-for="item in item.tableValue"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button @click="closeAdd" class="btn1 zh-btn">取消</el-button>
      <el-button class="main btn1" type="primary" @click="handleOkAdd" :disabled='isOk'
        >确认新增</el-button
      >
    </template>
  </el-dialog>
  <UpDateGoodsInfoVue  v-model:show="showUpdate" @close='getTemplate'/>
</template>

<script>
import {Close, Plus} from "@element-plus/icons-vue";
import {ElMessageBox} from "element-plus";
import UpDateGoodsInfoVue from './upDateGoodsInfo.vue'
import ModelOpen from "@views/product/productInformation/components/modelOpen/index.vue";
import {computed, getCurrentInstance, reactive, ref, watch ,watchEffect} from "vue";
import {customTemplate, productAdd} from "@/utils/api/product/product.js";
import {typeInfo} from "@/utils/api/type.js";
import uploadFile from "@/utils/uploadFile";
import {removeNoChild} from "@/utils/tool.js";
import {managerUrl} from "@/utils/api.js";
import SelectByAbcModal from '@/components/selectByAbcModal/index.vue'
import useProductModel from "@utils/tool/product/useProductModel";
import ModelInput from '@/components/form/ModelInput'
import ModelList from './ModelList/index.vue'

export default {
  components: {
    UpDateGoodsInfoVue,
    Plus,
    Close,
    SelectByAbcModal,
    ModelOpen,
    ModelInput,
    ModelList
  },
  props: {
    show: Boolean,
    goodsInfo: Object,
  },
  emits: ["update:show", "changeUpdate", "ok", "update:goodsInfo"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const {modelInfo} = useProductModel()
    const showDia = ref(props.show); // 控制弹窗是否展示
    const candiyConfig = {
      expandTrigger: "hover",
      value: "classifyId",
      label: "classifyName",
      children: "childList",
      checkStrictly: false,
    };
    //移除图片
    const handleRemoveImg = (file, fileList) => {
      addInfo.img = "";
    };
    //获取分类列表
    const getTypeList = async () => {
      let res = await typeInfo({});
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = res.data?.list || []
      //排除掉list 里面不包含childList的数据
      list = removeNoChild(list);
      typeOptions.value = list;
    };
    const typeOptions = ref([]);
    getTypeList();
    //获取模板信息
    const getTemplate = async () => {
      let res = await customTemplate();
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = res.data.list || [];
      list.forEach((item) => {
        if (item.type > 11 && item.tableValue) {
          item.tableValue = item.tableValue.split(",");
        }
      });
      typeList.value = list;
    };
    getTemplate()
    /*
    关于新增商品
    */
    const addInfo = reactive({
      followTime:'',
      validityPeriodType:3,
      qualityGuaranteePeriodType:2,
      qualityGuaranteePeriodNum:'',
      hasEncoded:true
    });
    //条码列表
    const barcodesList = ref([]);
    const barcode = ref("");
    const addBarcode = () => {
      if (!barcode.value) {
        return;
      }
      barcode.value = barcode.value.replace(/\s+/g,"");
       if (!barcode.value) {
        return;
      }
      if (barcodesList.value.includes(barcode.value)) {
        proxy.$message.error("已经存在，请重新输入");
         barcode.value = "";
        return;
      }
      barcodesList.value.push(barcode.value);
      barcode.value = "";
    };
    //添加条形码
    function addBarcodeByKeydown(e) {
       barcode.value = e
    }
    //删除条码
    const delBarcode = (item) => {
      const index  = barcodesList.value.findIndex((i) => i === item)
      barcodesList.value.splice(index, 1);
    };
    //规格型号列表
    const modelList = ref([]);
    const model = ref("");
    const addModel = () => {
      if (!model.value) {
        return;
      }
      model.value = model.value.replace(/\s+/g,"");
      if (!model.value) {
        return;
      }
      if (modelList.value.findIndex((item) => item.modelName === model.value) > -1) {
        proxy.$message.error("已经存在，请重新输入");
        model.value = ''
        return;
      }
      modelList.value.push({
        ...modelInfo,
        modelName: model.value,
      });
      model.value = "";
    };
    //删除条码
    const delModel = (item) => {
      const index  = modelList.value.findIndex((i) => i === item)
      modelList.value.splice(index, 1);
    };
    const typeList = ref([]); // 列表类型
    const loading = ref(false); // 加载中
    const beforeAvatarUpload = (file) => {
      if (
        !["image/png", "image/jpg", "image/jpeg"].some(
          (item) => item == file.type
        )
      ) {
        proxy.$message.error("图片格式不正确");
        return;
      }
      //限制file的大小不超过1MB
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        proxy.$message.error("上传图片大小不能超过 5MB!");
        return false;
      }
      let url = `${managerUrl}/carton/system/oss/policy?business=productImg`;
      loading.value = true;
      uploadFile(url, file).then((res) => {
        let imgUrl = res.url;
        addInfo.img = imgUrl;
      }).finally(()=>{
        loading.value = false;
      });
      return false;
    };

    watch([() => props.show, () => props.goodsInfo], (newValue, oldValue) => {
      if (newValue[0]) {
        getTemplate();
      }
      if (newValue[1] && newValue[1].productId) {
        setAddInfo(newValue[1]);
      } else {
        remakeAddInfo();
      }
      showDia.value = newValue[0];
    });
    watch(
      () => showDia.value,
      (newValue, oldValue) => {
        if (newValue == false) {
          emit("update:show", newValue);
        }
      }
    );
    function remakeAddInfo() {
      let list = Object.keys(addInfo);
      barcodesList.value = [];
      modelList.value = [];
      barcode.value = ''
      model.value = ''
      list.forEach((item) => {
        addInfo[item] = "";
        if (item === "followTime") {
          addInfo[item] = '';
        }
        if (item === "validityPeriodType") {
          addInfo[item] = 3;
        }
        if (item === "qualityGuaranteePeriodType") {
          addInfo[item] = 2;
        }
        if (item === "qualityGuaranteePeriodNum") {
          addInfo[item] = '';
        }
        if (item === "hasEncoded") {
          addInfo[item] = true;
        }
      });

    }
    //设置数据
    function setAddInfo(obj) {
      let list = Object.keys(obj);
      barcodesList.value = [];
      modelList.value = [];
      barcode.value = ''
      model.value = ''
      list.forEach((item) => {
        addInfo[item] = obj[item];
        if (item === "encoded" && obj[item]) {
            barcodesList.value = obj[item];
          }
        if (item === "model" && obj[item]) {
            if(obj[item]&& obj[item].length >0){
              obj[item].forEach(item=>{
                modelList.value.push({
                  ...item,
                  scattered:false,
                  packing:false,
                  discard:false,
                  scatteredName: '',
                  scatteredCount: '',
                  modelId:'',
                  orders:0
                })
              })
            }
          }
        if (item === "brandId" && obj[item]) {
            addInfo.brand = [{
              id:obj[item],
              name: obj.brandName || '',
            }]
          }
        if (item === "unitId" && obj[item]) {
            addInfo.unit = [{
              id:obj[item],
              name: obj.unitName || '',
              unitName: obj.unitName || ''
            }]
          }
        if (item === "followTime") {
          addInfo[item] = obj[item] === "关注" || obj[item] ? true : false;
          }
        });
        typeList.value.forEach((item) => {
          if (item.type === 13 && addInfo[item.addKey]) {
          addInfo[item.addKey] = addInfo[item.addKey].split(",");
        }
      });
      }
    const isOk =  computed(() => {
        for (let i = 0; i < typeList.value.length; i++) {
           let addKey = typeList.value[i].addKey
        if (typeList.value[i].required && !addInfo[addKey]) {
          if (addKey === "followTime") {
            continue;
          }
          if (
              addKey === "encoded" &&
            (((barcodesList.value.length >0 || barcode.value ) && addInfo.hasEncoded ) ||  !addInfo.hasEncoded ))
           {
            continue;
          }
          if (
              addKey === "model" && (modelList.value.length > 0 || model.value)) {
            continue;
          }
          return true
        }
      }
      return false
    })
    /*
    确认新增
    */
    const handleOkAdd = async () => {
      if (addInfo.hasEncoded && barcodesList.value.length === 0 && !barcode.value) {
        proxy.$message.error("条码未录入");
        return
      }
      addInfo.model =[]
      addInfo.addModel = []
      if (modelList.value.length > 0) {
        modelList.value.forEach((item) => {
          addInfo.addModel.push({...item})
        });
      }
      if (model.value !== '') {
        //去除model.value中的空格
        model.value = model.value.replace(/\s+/g,"");
        addInfo.addModel.push({
          ...modelInfo,
          modelName: model.value,
        })
      }
      addInfo.encoded = [];
      if(addInfo.hasEncoded){
        if (barcodesList.value.length > 0) {
          addInfo.encoded = barcodesList.value;
        }
        if (barcode.value !== '') {
          //去除barcode中的空格
          barcode.value = barcode.value.replace(/\s+/g,"");
          addInfo.encoded.push(barcode.value)
        }
      }
      if (addInfo.classifyId?.length > 0) {
        addInfo.classifyId = addInfo.classifyId[addInfo.classifyId.length - 1];
      }
      addInfo.productId = "";
      for (let i = 0; i < typeList.value.length; i++) {
        if (typeList.value[i].required && !addInfo[typeList.value[i].addKey]) {
          if (
            typeList.value[i].addKey == "followTime" &&
            (addInfo[typeList.value[i].addKey] == true ||
              addInfo[typeList.value[i].addKey] == false)
          ) {
            break;
          }
          proxy.$message.error(`请填写${typeList.value[i].title}`);
          return;
        }
      }
      typeList.value.forEach((item) => {
        if(item.type== 13 && addInfo[item.addKey]){
          addInfo[item.addKey] = addInfo[item.addKey].toString();
        }
      });
      let res = await productAdd(addInfo);
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      proxy.$message.success("添加成功");
      emit("ok", {});
      showDia.value = false;
    };
    const closeAdd = () => {
      ElMessageBox.confirm("信息未保存，是否确认取消！", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
        showDia.value = false;
      });
    };
    //切换自定义字段
    const changeUpdate = () => {
      showDia.value = false;
      emit("changeUpdate", {});
    };
    //展示错误标识
    const isRepeat = ref(false);
    //确认品牌
    const getBrand = () => {
      addInfo.brandId = addInfo.brand?.[0]?.brandId || '';
      addInfo.brandName = addInfo.brand?.[0]?.brandName || '';
    };
    //选择单位
    const getUnit = () => {
      addInfo.unitId = addInfo.unit?.[0]?.unitId || '';
      addInfo.unitName = addInfo.unit?.[0]?.unitName || '';
    };


    const showUpdate = ref(false)
    //自定义字段 弹窗展示
    const handleClickUpdate = ()=>{
      showUpdate.value = true
    }
    watchEffect(()=>{
      if(addInfo.unit?.[0]?.unitName){
        modelList.value.forEach(item=>{
          item.unit = addInfo.unit?.[0]?.unitName
        })
      }
    })
    return {
      showUpdate,
      handleClickUpdate,
      getUnit,
      getBrand,
      isRepeat,
      changeUpdate,
      closeAdd,
      handleOkAdd,
      //产品分类
      typeOptions,
      //品牌单位
      delBarcode,
      candiyConfig,
      showDia,
      typeList,
      beforeAvatarUpload,
      barcodesList,
      addInfo,
      modelList,
      barcode,
      model,
      addBarcode,
      addModel,
      handleRemoveImg,
      delModel,
      isOk,
      getTemplate,
      loading
    };
  },
};
</script>
<style lang="scss" scoped>
.addGoodsNew {
  .content {
    padding: 0 32px;
    height: 700px;
    box-sizing: border-box;
    @include overFlow;
    .error_tips{
      height: 32px;
      line-height: 32px;
      background: rgba(255, 107, 107,0.1);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      font-size: 12px;
      .pic{
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
      .error_text{
        color: #E64E4E;
      }
    }
    .diaTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 2px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }
      .btnBox {
        .el-button {
          margin-left: 16px;
          font-size: 14px;
          padding: 6px 12px;
          &.btn {
            color: $fsColor;
            border: 1px solid #DCDEE0;
          }
        }
      }
    }
  }
  .query {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  >.item {
    width: 360px;
    margin-bottom: 20px;
    font-weight: 400;
    &:nth-of-type(2n){
      >div{
        width: 360px;
        margin-left: auto;
      }
    }
    .add_time_box{
      border-radius: 8px;
      background: #FAFBFC;
      width: 360px;
      padding: 0 18px ;
      height: 0;
      overflow: hidden;
      transition:all 0.2s linear;
      box-sizing: border-box;
      &.active{
        height: 104px;
        border: 1px solid #DCDEE0;
      }
      .add_time_set{
        display: flex;
        align-items: center;
        margin-top: 10px;
         .timeChoose {
      // width: 72px;
      border-radius: 8px;
      border: 1px solid #dcdee0;
      height: 38px;
      overflow: hidden;
      .el-input__wrapper {
        border: none !important;
        box-shadow: none !important;
        padding-right: 0;
      }
      .el-input__inner {
        border: none !important;
        box-shadow: none !important;
      }

      .el-select {
        --el-select-input-focus-border-color: transparent;
        .el-input__wrapper {
          padding-right: 4px;
          .el-input__suffix-inner > :first-child {
            margin-left: 2px;
          }
          .el-input__inner {
            flex-grow: 0;
            width: 14px;
          }
        }
      }
      .nbd {
        input {
          border: none !important;
        }
      }
      .timeChooseInput {
        width: 50px;
        position: relative;
        .el-input__inner {
          flex-grow: 0;
          position: relative;
          top: 1px;
        }
      }
      .timeChooseInput2 {
        width: 50px;
      }
    }
        .add_time_subtext{
          color: #AAAAB2;
        }
        .add_time_text{
          color: #737480;
          margin-right: 8px;
        }
      }
    }
    .tabList{
      margin-top: 10px;
    }
    .barcodeList{
      display: flex;
      align-items: center;
      .add_title{
        //margin-right: 116px;
      }
      .el-radio-group{
        position: relative;
        top: -8px;
        margin-left: auto;
      }
    }
    .modelList {
      display: flex;
      align-items: center;
      .add_title {
        margin-right: auto;
      }

    }
    .red {
      color: #e54e4e;
      position: relative;
      top: 2px;
    }
    .queryInput {
      width: 360px;
      box-sizing: border-box;
      &.addInput{
        :deep(.el-input__wrapper){
          border: none;
            box-shadow: none;
        }
      }
    }
    .input {
      margin-top: 10px;
       position: relative;
       width: 362px;
       &.add_code_input{
        border: 1px solid var(--el-input-border-color, #DCDEE0);
        border-radius: 8px;
        overflow: hidden;
        .add_code_list{
              max-height: 0;
            min-height: 0;
          background-color: #FAFBFC;
          transition:all 0.2s linear;
           @include overFlow();
          &.active{
              max-height: 200px;
          }
        }
       }
      .add_code_content{
          position: relative;
          .addBtn {
          height: 36px;
          line-height: 36px;
          position: absolute;
          left: 316px;
          top: 0;
          color: $fsColor3;
          margin-right: 10px;
          font-weight: normal;
          cursor: pointer;
        }
      }

      .avatar-uploader {
        width: 120px;
        height: 120px;
        background: #f2f4f7;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .imgBox{
          position: relative;
         width: 120px;
        height: 120px;
        .icon-close{
            position: absolute;
            top: -10px;
            right: -10px;
            color: #fff;
            background-color: rgba(0,0,0,.5);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            cursor: pointer;
          }
        .avatar{
           width: 120px;
        height: 120px;
        }
        }
        .avatar-uploader-icon {
          font-size: 40px;
          line-height: 126px;
          color: #dcdee0;
        }
        .avatar {
          width: 168px;
          height: 126px;
          border-radius: 8px;
          overflow: hidden;
        }
      }
    }
  }
}
}

:deep(.el-alert__description) {
  margin-top: 0;
  font-weight: normal;
  .fsColor2 {
    color: $fsColor2;
  }
  .text {
    display: flex;
    align-items: center;
  }
  .alert_icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    color: #fff;
    background-color: #e54e4e;
    font-size: 12px;
    text-align: center;
    margin-right: 4px;
  }
}

:deep(.el-alert__icon) {
  width: 14px !important;
  height: 14px;
}

</style>
