<template>
  <div class="normalList" v-loading='loading'>
    <!-- 正常信息列表 -->
    <div class="queryBoxOne">
      <div class="btnBox">
        <add-btn @click="add"></add-btn>
        <el-button class="ml_16 zh-btn" @click="upload">
          <export-img class="up"></export-img>
          导入
        </el-button>
      </div>
      <form class="queryItem" @submit.prevent="getList">
        <el-input
          v-model="formData.query"
          class="w200"
          placeholder="产品名称/简称/条码"
          clearable
          v-elInput
        />
        <el-cascader
          v-model="formData.classifyId"
          :options="typeOptions"
          :props="candiyConfig"
          placeholder="产品分类"
          class="w200"
          @change="getList"
          clearable
          filterable
        >
        </el-cascader>
        <SelectByModalNew type="brand" @on-change="getList" v-model="formData.brandId"></SelectByModalNew>
        <ScatteredSelect @change="getList" v-model="formData.scattered"/>
        <div class="queryBtn">
          <el-button type="primary" native-type="submit"
            ><el-icon><Search /></el-icon
          ></el-button>
          <el-button @click="fieldCheckShow">展示项设置</el-button>
        </div>
      </form>
    </div>
    <div class="tabList">
      <!-- 列表 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        class="zh-no-table-border"
        @row-click="checkGoods"
        height="100%"
        :row-class-name="tableRowClassName"
        :cell-style="{border:'none'}"
        :header-row-style="{border:'none'}"
      >
        <el-table-column
          type="index"
          width="100"
          label="序号"
          align="center"
          fixed
        />
        <template v-for="item in titleList" :key="item.fieldKey">
          <el-table-column
              v-if="item.fieldKey === 'img'"
            align="center"
            :prop="item.fieldKey"
            :label="item.title"
          >
            <template #default="scope">
               <el-popover placement="right" :width="400" trigger="hover"  popper-class='popImg'>
                   <template #reference>
                     <img :src="scope.row.img" class="img" alt="位元灵感"/>
                  </template>
                 <img :src="scope.row.img" class="imgBig" alt="位元灵感"/>
                </el-popover>
            </template>
          </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'productName'"
            align="center"
            :label="item.title"
            width="280"
            show-overflow-tooltip
            class-name="table_text2"
          >
            <template #default="scope">
              {{scope.row.productName}}
            </template>
          </el-table-column>
             <el-table-column
                 v-else-if="item.fieldKey === 'subName'"
              align="center"
              :label="item.title"
              width="150"
              show-overflow-tooltip
             class-name="table_text"
          >
            <template #default="scope">
              {{scope.row.subName}}
            </template>
          </el-table-column>
             <el-table-column
                 v-else-if="item.fieldKey === 'encoded'"
            align="center"
            :label="item.title"
            width="200"
          >
            <template #default="scope">
                 <more-list :list='scope.row.encoded'></more-list>
            </template>
          </el-table-column>
             <el-table-column
                 v-else-if="item.fieldKey === 'model'"
            align="center"
            :label="item.title"
            :class-name="item.className || 'table_text2'"
            width="200"
          >
            <template #default="scope">
                 <more-list :list='scope.row.model'></more-list>
            </template>
          </el-table-column>
           <el-table-column
            v-else
            align="center"
            :prop="item.fieldKey"
            :label="item.title"
            :width="item.title.length * 50"
            :class-name="item.className || 'table_text'"
            show-overflow-tooltip
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="mainPage">
      <el-pagination
        v-model:current-page="pageInfo.pageNumber"
        layout="total, prev, pager, next , jumper"
        :total="pageInfo.total"
         v-model:page-size='pageInfo.pageSize'
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 详情弹窗 -->
    <GoodsInfoModal v-model="showGoodsInfo" v-if='showGoodsInfo' @close='closeGoodsInfo' typeName='normal' @copyGood='copyGoodZh'  class="attr"/>
    <!-- 自定义字段弹窗 -->
    <FieldsCheck
      :list="titleListForCheck"
      :show="customFields"
      @handleOk="checkFileds"
      ref="fieldsCheck"
    />
    <!-- 详情弹窗 -->
    <!-- 新增产品弹窗 -->
    <add-goods v-model:show="addShowDia" @changeUpdate='changeUpdate' @ok='okAddGoods' v-model:goodsInfo="copyGoodsInfo"></add-goods>
    <!-- 自定义产品弹窗 -->
    <UpDateGoodsInfo v-model:show="upDateShowDia" ></UpDateGoodsInfo>
    <up-load-goods ref="uploadRefs" @close-upload="getList"></up-load-goods>
  </div>
</template>
<script>
import {customTemplate, productInfo, productList} from "@/utils/api/product/product.js";
import GoodsInfoModal from "@/components/goods/goodsInfoModal/index.vue";
import {getCurrentInstance, onMounted, reactive, ref, watch} from "vue";
import {ArrowDown, Plus, Search} from "@element-plus/icons-vue";
import {useStore} from 'vuex';
import {reactiveAssign, removeNoChild, tableRowClassName} from "@/utils/tool.js";
import FieldsCheck from "@/components/fieldsCheck.vue";
import {toRaw} from "@vue/reactivity";
import AddGoods from "./addGoods.vue";
import UpDateGoodsInfo from "./upDateGoodsInfo.vue";
import {cloneDeep} from "lodash";
import {typeInfo} from "@/utils/api/type.js";
import MoreList from '../../../../components/moreList/moreList.vue';
import SelectByAbcModal from '@/components/selectByAbcModal/index.vue'
import UpLoadGoods from "@views/product/productInformation/components/upload/index.vue";
import AddBtn from "@components/element/addBtn.vue";
import ExportImg from "@components/exportImg.vue";
import {usePagination} from "@utils/tool/page";
import SelectByModalNew from "@components/form/selectByModalNew/index.vue";
import ScatteredSelect from "@components/product/scatteredSelect/index.vue";

export default {
  name: "normalList",
  components: {
    ExportImg,
    Search,
    ArrowDown,
    Plus,
    FieldsCheck,
    AddGoods,
    UpDateGoodsInfo,
    MoreList,
    SelectByAbcModal,
    GoodsInfoModal,
    UpLoadGoods,
    AddBtn,
    SelectByModalNew,
    ScatteredSelect
  },
  emits: ["changeCount"],
  setup(props, { emit }) {
    const loading = ref(false)
    const store = useStore();
    const uploadRefs = ref(null)
    const { proxy } = getCurrentInstance();
    const chooseGoodsId = ref("10");
    const fieldsCheck = ref(null);
    const customFields = ref(false);
    const formData = reactive({
    query: "",
    classifyId: "",
    brandName: "",
    brandId: "",
    brand:[],
    scattered:"",
    });
    const {pageInfo} = usePagination()
    /*
      关于产品基本信息列表
    */
   let isFirst = false
    const getList = async () => {
     emit('changeCount',{})
     loading.value = true
      let obj = Object.assign(pageInfo,formData)
      if(obj?.classifyId?.length > 0){
      obj.classifyId = obj.classifyId[obj.classifyId.length - 1]
      }
      let res = await productList(obj);
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      tableData.value = res.data.field || [];
      if(!isFirst){
        isFirst = true
        titleList.value = cloneDeep(res.data.title).sort((a,b)=>a.sort - b.sort) || [];
        titleListForCheck.value = cloneDeep(res.data.title).sort((a,b)=>a.sort - b.sort) || [];
      }
      pageInfo.total = res.data.total || 0
      setTimeout(()=>{
        loading.value=false
      },100)
    };
    //获取分类列表
    const getTypeList = async () => {
      let res = await typeInfo({});
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = res.data?.list || []
      //排除掉list 里面不包含childList的数据
      list = removeNoChild(list);
      typeOptions.value = list;
    };
    const typeOptions = ref([]);
    getList();
    getTypeList();
    const tableData = ref([]);
    let titleList = ref([{
      fieldKey:'img',
      title:'产品图片',
      img:''
    }]);
    const titleListForCheck = ref([{
      fieldKey:'img',
      title:'产品图片',
      img:''
    }]);
    const handleCurrentChange = (val) => {
      pageInfo.pageNumber = val
      getList()
    };
    const checkFileds = (list) => {
      let arr = toRaw(list);
      arr = arr.sort((a, b) => a.sort - b.sort);
      titleList.value = arr;
    };
    const fieldCheckShow = () => {
      customFields.value = true;
      fieldsCheck.value.handleClickBtn();
    };
    // 关于新增商品
    const addShowDia = ref(false);
    const add = () => {
      copyGoodsInfo.value = {}
      addShowDia.value = true;
    };
    const candiyConfig = {
      expandTrigger: "hover",
      value: "classifyId",
      label: "classifyName",
      children: "childList",
      checkStrictly: true,
    };

  /*
  关于选择品牌
  */
    /*
      关于自定义字段
    */
   const upDateShowDia = ref(false)
   const changeUpdate = ()=>{
      addShowDia.value = false
      upDateShowDia.value = true
   }
   const okAddGoods = ()=>{
      getList()
   }

   /*
    产品详情
  */
 //重制商品详情对象
  const remakeGoodsInfo = (row)=>{
      row.list = []
      titleListForCheck.value.forEach((item)=>{
            row.list.push({
            title:item.title,
            value:row[item.fieldKey],
            fieldKey:item.fieldKey
          })
      })
      return row
  }
  const changeGoodsDia = ref(false)
  const showGoodsInfo = ref(false)
  const chooseGoods = ref({})
  const checkGoods = (row)=>{
    let obj = cloneDeep(row)
    getGoodsDetails(obj)
  }
  //获取产品详情
const getGoodsDetails = (e)=>{
  let goodsInfo ={}
  productInfo({
    productId:e.productId
  }).then((res)=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    if(!res.data?.img){
      res.data.img = 'https://oss.musaemotion.com/WEB/assets/img/index/productDefaultImg.png'
    }
    reactiveAssign(goodsInfo,res.data)
     getTemplate().then((res)=>{
       goodsInfo.list = []
      res.forEach((item)=>{
        goodsInfo.list.push({
          title:item.title,
          value:goodsInfo[item.fieldKey],
          fieldKey:item.fieldKey
        })
    })
    store.commit('GOODS_INFO',goodsInfo)
    showGoodsInfo.value = true
    })
  })
  }
  //获取模板信息
const getTemplate = async () => {
  let res = await customTemplate();
  if (res.code !== 0) {
    proxy.$message.error(res.msg);
    return;
  }
  return res.data.list || []
};
    watch([() => changeGoodsDia.value, () => addShowDia.value], () => {
      getList()
    })
  //复制产品
  const copyGoodZh = (e)=>{
    copyGoodsInfo.value = cloneDeep(e)
    addShowDia.value = true
    showGoodsInfo.value =false
  }
   const copyGoodsInfo = ref({})
   const testList = ref([])
   const closeGoodsInfo = ()=>{
    showGoodsInfo.value = false
    getList()
    }
    onMounted(() => {
      if(history.state?.add){
        add()
        history.state.add = false
      }
    });

    const upload = () => {
      uploadRefs.value.showUpLoad = true
    }
    return {
      closeGoodsInfo,
      testList,
      loading,
      copyGoodsInfo,
      chooseGoods,
      showGoodsInfo,
      checkGoods,
      changeGoodsDia,
      okAddGoods,
      changeUpdate,
      upDateShowDia,
      typeOptions,
      addShowDia,
      add,
      formData,
      tableData,
      titleList,
      pageInfo,
      handleCurrentChange,
      checkFileds,
      customFields,
      fieldCheckShow,
      titleListForCheck,
      fieldsCheck,
      chooseGoodsId,
      candiyConfig,
      getList,
      tableRowClassName,
      copyGoodZh,
      uploadRefs,
      upload
    };
  },
};
</script>


<style lang="scss" scoped>
.normalList {
  height: 100%;
   box-sizing: border-box;
  position: relative;
  .tabList {
    margin-top: 20px;
    height: calc(100% - 76px);
    box-sizing: border-box;
    padding-bottom: 52px;
   }
  .imgList {
    .img {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      overflow: hidden;
      object-fit: cover;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

  }
}
:deep(.el-table__row) {
  cursor: pointer;
}
.popImg{
      width: 100%;
      height: 400px;
    .imgBig{
      width: 100%;
      height: 380px;
      object-fit: cover;
    }
    }

.up {
  margin-right: 4px;
  //翻转
  transform: rotate(180deg);
}
</style>
