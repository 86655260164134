<template>
  <div class="model-list">
      <div class="item" v-for="(item,index) in modelValue" :key="item.modelName">
          <div class="model-name">
            <div class="name">{{item.modelName}}</div>
            <div class="control">
              <span class="cur_p" v-if="item.modelId" @click="handleDiscard(index)">{{item.discard ? '恢复':'作废'}}</span>
              <ModelInput  v-if="item.orders === 0" v-model="modelValue" :unit="item.unit" update :modelName="item.modelName"></ModelInput>
              <img src="@/assets/img/index/del.png" @click="handleDel(index)" v-if="!item.modelId" class="img ml_16" />
            </div>
          </div>
          <div class="model-single" v-if="item.buyPrice">
            <div class="key">采购价（元）：</div>
            <div class="val">{{$filters.toYuan(item.buyPrice,'元') }}</div>
          </div>
          <div class="model-single" v-if="item.sellPrice">
            <div class="key">销售价（元）：</div>
            <div class="val">{{$filters.toYuan(item.sellPrice,'元')}}</div>
          </div>
          <div class="model-single" v-if="item.scattered">
            <div class="key">拆零：</div>
            <div class="val weight">1{{ item.unit || '产品单位'}}={{ item.scatteredCount }}{{ item.scatteredName }}</div>
          </div>
      </div>
  </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core';
import ModelInput from '@/components/form/ModelInput/index.vue';
const props = defineProps({
  modelValue:{
    type:Array,
    default:()=>([])
  },
})
const emit = defineEmits(['update:modelValue'])
const modelValue = useVModel(props,'modelValue', emit)
//删除
const handleDel = (index) => {
  modelValue.value.splice(index,1)
}
//点击作废
const handleDiscard = (i)=>{
  console.log(modelValue.value[i])
  modelValue.value[i].discard = !modelValue.value[i].discard
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>