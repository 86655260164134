<template>
  <div class="voidList" v-loading='loading'>
    <!-- 正常信息列表 -->
    <div class="queryBoxOne">
      <div class="btnBox">
      </div>
     <div class="queryItem">
        <el-input
          v-model="formData.query"
          class="w200"
          placeholder="产品名称/简称/条码"
          v-elInput
        />
        <el-cascader
          v-model="formData.classifyId"
          :options="typeOptions"
          :props="candiyConfig"
          placeholder="产品分类"
          class="w200"
          clearable
        >
        </el-cascader>
       <SelectByAbcModal typeName="brand" placeholder="请选择品牌" :maxNum="1" v-model:list="formData.brand"
                         className="w240 " @change="getList"></SelectByAbcModal>
        <div class="queryBtn">
          <el-button type="primary" @click="getList"
            ><el-icon><Search /></el-icon
          ></el-button>
          <el-button @click="fieldCheckShow">展示项设置</el-button>
        </div>
      </div>
    </div>
    <div class="tabList">
      <!-- 列表 -->
     <el-table
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @row-click="checkGoods"
         height="100%"
        :cell-style="{border:'none'}"
        class="zh-no-table-border"
        :header-row-style="{border:'none'}"
      >
        <el-table-column
          type="index"
          width="100"
          label="序号"
          align="center"
          fixed
        />
        <template v-for="item in titleList" :key="item.fieldKey">
          <el-table-column
              v-if="item.fieldKey === 'img'"
            align="center"
            :prop="item.fieldKey"
            :label="item.title"
          >
            <template #default="scope">
               <el-popover placement="right" :width="400" trigger="hover"  popper-class='popImg'>
                   <template #reference>
                     <img :src="scope.row.img" alt="位元灵感" class="img"/>
                  </template>
                 <img :src="scope.row.img" alt="位元灵感" class="imgBig"/>
                </el-popover>
            </template>
          </el-table-column>
             <el-table-column
            v-else-if="item.fieldKey == 'productName'"
            align="center"
            :label="item.title"
            width="280"
            show-overflow-tooltip
          >
            <template #default="scope">
              {{scope.row.productName}}
            </template>
          </el-table-column>
             <el-table-column
            v-else-if="item.fieldKey == 'subName'"
            align="center"
            :label="item.title"
            width="150"
            show-overflow-tooltip
          >
            <template #default="scope">
              {{scope.row.subName}}
            </template>
          </el-table-column>
             <el-table-column
            v-else-if="item.fieldKey == 'encoded'"
            align="center"
            :label="item.title"
            width="200"
          >
            <template #default="scope">
              <div class="moreListContent">
                 <more-list :list='scope.row.encoded'></more-list>
              </div>
            </template>
          </el-table-column>
             <el-table-column
            v-else-if="item.fieldKey == 'model'"
            align="center"
            :label="item.title"
            width="200"
          >
            <template #default="scope">
              <div class="moreListContent">
                 <more-list :list='scope.row.model'></more-list>
              </div>
            </template>
          </el-table-column>
           <el-table-column
            v-else
            align="center"
            :prop="item.fieldKey"
            :label="item.title"
            :width="item.title.length * 50"
            show-overflow-tooltip
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
     <div class="mainPage">
        <el-pagination
          v-model:current-page="pageInfo.pageNumber"
          layout="total, prev, pager, next , jumper"
          :total="pageInfo.total"
           v-model:page-size='pageInfo.pageSize'
          @current-change="handleCurrentChange"
        />
      </div>
    <SelectByAbc
      :show="chooseBrand"
      :list="AbcList"
      title="品牌"
      unit="品牌名称"
      @search="searchAbc"
      :maxNum="1"
      @handleOk="saveAbc"
      @close="closeChoseBrand"
      :update="true"
      @add="addBrand"
      @del="delBrand"
    />
    <!-- 自定义字段弹窗 -->
    <FieldsCheck :list='titleListForCheck'  :show='customFields' @handleOk='checkFileds' ref='fieldsCheck'/>
      <!-- 详情弹窗 -->
    <GoodsInfoModal  typeName='void' @close='closeGoodsInfo' v-model="showGoodsInfo"/>
  </div>
</template>
<script>
import SelectByAbcModal from '@/components/selectByAbcModal/index.vue'
import {customTemplate, productDiscardList, productInfo} from "@/utils/api/product/product.js";
import GoodsInfoModal from "@/components/goods/goodsInfoModal/index.vue";
import {getCurrentInstance, onDeactivated, onMounted, reactive, ref, watch} from "vue";
import {ArrowDown, Plus, Search} from "@element-plus/icons-vue";
import SelectByAbc from "@/components/selectByAbc/index.vue";
import FieldsCheck from '@/components/fieldsCheck.vue'
import {toRaw} from '@vue/reactivity'
import {useStore} from 'vuex';
import {brandAdd, brandDel, brandList} from "@/utils/api/product/brand.js";
import {typeInfo} from "@/utils/api/type.js";
import {cloneDeep} from 'lodash'
import MoreList from '../../../../components/moreList/moreList.vue';
import {reactiveAssign, removeNoChild, tableRowClassName} from '@/utils/tool.js'
import {usePagination} from "@utils/tool/page";

export default {
  name: "voidList",
  components: {
    Search,
    ArrowDown,
    Plus,
    FieldsCheck,
    SelectByAbc,
    MoreList,
    GoodsInfoModal,
    SelectByAbcModal
  },
  emits: ["changeCount"],
  setup(props, { emit }) {
    const loading = ref(false)
    const {proxy } = getCurrentInstance()
        const store = useStore();
    const fieldsCheck = ref(null)
    const customFields = ref(false)
    const formData = reactive({
      query: "",
      classifyId: "",
      brandName: "",
      brandId: "",
      brand:[]
    });
    const {pageInfo} = usePagination()
       let isFirst = false
  const getList = async()=>{
     emit('changeCount',{})
      formData.brandName = formData.brand[0]?.name || ''
      formData.brandId = formData.brand[0]?.id || ''
     loading.value = true
      let obj = Object.assign(pageInfo,formData)
      if(obj?.classifyId?.length > 0){
      obj.classifyId = obj.classifyId[obj.classifyId.length - 1]
      }
      let res = await productDiscardList(pageInfo);
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
     }
     tableData.value = res.data.field || []
    if(!isFirst){
        isFirst = true
        titleList.value = cloneDeep(res.data.title).sort((a,b)=>a.sort - b.sort) || [];
        titleListForCheck.value = cloneDeep(res.data.title).sort((a,b)=>a.sort - b.sort) || [];
      }
     pageInfo.total = res.data.total || 0
    emit('changeCount',{})
    setTimeout(()=>{
      loading.value=false
    },100)
   }
   getList()
    const tableData = ref([]);
    let titleList = ref([{
      fieldKey:'img',
      title:'产品图片',
      img:''
    }]);
    const titleListForCheck = ref([])
     const handleCurrentChange = (val) => {
      pageInfo.pageNumber = val
      getList()
    };
    const checkFileds = (list) =>{
      let arr = toRaw(list)
      arr= arr.sort((a,b)=>a.sort - b.sort)
      titleList.value = arr
    }
    const fieldCheckShow = ()=>{
      customFields.value = true
      fieldsCheck.value.handleClickBtn()
    }
    const chooseBrand = ref(false);
    //弹出选择品牌弹窗
    const hanldeChooseBrand = () => {
      getBrandList();
      chooseBrand.value = !chooseBrand.value;
    };
    //选择品牌弹窗关闭
    const closeChoseBrand = (val) => {
      chooseBrand.value = val;
    };
    const AbcList = ref([]);
    //获取品牌分类列表
    const getBrandList = async (query) => {
      let res = await brandList({
        brandName: query || "",
      });
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      AbcList.value = res.data.list;
    };
    //弹出品牌选择 弹窗
    const searchAbc = (e) => {
      getBrandList(e);
    };
    const saveAbc = (e) => {
      if (e[0]?.name) {
        formData.brandName = e[0].name;
        formData.brandId = e[0].id;
      } else {
        formData.brandName = "";
        formData.brandId = "";
      }
    };
    //新增品牌
    const addBrand = async (e) => {
      let res = await brandAdd({
        brandName: e,
      });
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      getBrandList();
    };
    //删除品牌
    const delBrand = async (e) => {
      let res = await brandDel({
        brandId: e.id,
      });
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      proxy.$message.success("移除成功");
    };
    const showGoodsInfo = ref(false)
     const chooseGoods = ref({})
  const checkGoods = (row)=>{
    let obj = cloneDeep(row)
     getGoodsDetails(row)
  }
    watch([()=>showGoodsInfo.value],(newValue, oldValue) => {
      if(!newValue[0]){
        getList()
      }
    })
      //获取产品详情
const getGoodsDetails = (e)=>{
  let goodsInfo ={}
  productInfo({
    productId:e.productId
  }).then((res)=>{
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    reactiveAssign(goodsInfo,res.data)
     getTemplate().then((res)=>{
       goodsInfo.list = []
      res.forEach((item)=>{
        goodsInfo.list.push({
          title:item.title,
          value:goodsInfo[item.fieldKey],
          fieldKey:item.fieldKey
        })
    })
     store.commit('GOODS_INFO',goodsInfo)
    showGoodsInfo.value = true
    })
  })
  }
    //获取模板信息
const getTemplate = async () => {
  let res = await customTemplate();
  if (res.code != 0) {
    proxy.$message.error(res.msg);
    return;
  }
  let list = res.data.list || [];
  return list
};
     //重制商品详情对象
  const remakeGoodsInfo = (row)=>{
      row.list = []
      titleListForCheck.value.forEach((item)=>{
          row.list.push({
            title:item.title,
            value:row[item.fieldKey],
            fieldKey:item.fieldKey
          })
      })
      return row
  }
    const typeOptions = ref([]);
     //获取分类列表
    const getTypeList = async () => {
      let res = await typeInfo({});
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = res.data?.list || []
      //排除掉list 里面不包含childList的数据
      list = removeNoChild(list);
      typeOptions.value = list;
    };
      getTypeList();
    const candiyConfig = {
      expandTrigger: "hover",
      value: "classifyId",
      label: "classifyName",
      children: "childList",
    };
      let keydown = (e)=>{
      if (e.code == 'Enter') {
        getList()
      }
    }
    onMounted(()=>{
      window.addEventListener("keydown",keydown);
    })
    onDeactivated(()=>{
      window.removeEventListener("keydown",keydown);
    })
       const closeGoodsInfo = ()=>{
    showGoodsInfo.value = false
    getList()
    }
    return {
      closeGoodsInfo,
      loading,
      typeOptions,
      candiyConfig,
      showGoodsInfo,
      chooseGoods,
      checkGoods,
      getList,
      chooseBrand,
      hanldeChooseBrand,
      closeChoseBrand,
      AbcList,
      getBrandList,
      searchAbc,
      saveAbc,
      addBrand,
      delBrand,
      formData,
      tableData,
      titleList,
      pageInfo,
      handleCurrentChange,
      checkFileds,
      customFields,
      fieldCheckShow,
      titleListForCheck,
      fieldsCheck,
      tableRowClassName
    };
  },
};
</script>

<script setup></script>

<style lang="scss" scoped>
.voidList {
  height: 100%;
   box-sizing: border-box;
  position: relative;
  .tabList {
    margin-top: 20px;
    height:calc(100% - 86px);
    box-sizing: border-box;
    padding-bottom: 52px;
   }
  .imgList {
    cursor: pointer;
    img {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      overflow: hidden;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
:deep(.el-table__row) {
  cursor: pointer;
}
.popImg{
      width: 100%;
      height: 400px;
    .imgBig{
      width: 100%;
      height: 380px;
    }
    }
</style>
