<template>
  <div>
    <!-- 产品详情弹窗 -->
    <el-dialog
      v-model="showDia"
      title="产品信息-自定义字段"
      width="920"
      class="addGoods"
      :show-close="false"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
    >
      <div class="content">
        <div class="diaTitle">
          <div class="title">现有字段（红色为必填项）</div>
          <div class="btnBox"></div>
        </div>
        <draggable
          class="query"
          :list="typeList"
          ghost-class="ghost"
          chosen-class="chosenClass"
          animation="300"
          @start="onStart"
          @end="onEnd"
          item-key="fieldKey"
        >
          <template #item="{ element }">
            <div class="item cur_p">
              <div class="update_single w360 dp_f">
                <div class="update_name">
                  <span class="red" v-if='element.required'>*</span>
                  <span>{{element.title}}</span>
                </div>
                <div class="update_done">
                  <img src="../../../../assets/img/product/update.png" v-if='element.type > 10' @click='edit(element)' class="update_img">
                  <img src="../../../../assets/img/product/done.png" class="done_img">
                </div>
                <img class="delImg" src="../../../../assets/img/index/delete.png" v-if='element.type > 10' @click='del(element)'/>
              </div>
            </div>
          </template>
        </draggable>
        <div class="moreBtn cur_p" @click="addFildKey">
          <el-icon><Plus /></el-icon>
          <span>添加字段</span>
        </div>
      </div>
        <template #footer>
        <el-button @click="showDia=false"  class="btn1 zh-btn">取消</el-button>
        <el-button class="main btn1" type="primary" @click="handleSave"
          >保存</el-button
        >
      </template>
    </el-dialog>
    <!-- 添加字段弹窗 -->
    <!-- 产品详情弹窗 -->
    <el-dialog v-model="addDia" title="添加字段" width="640" class="addGoods" @close="closeAddDia" destroy-on-close align-center :close-on-click-modal="false">
      <div class="content">
        <el-form
          label-width="160px"
          style="max-width: 640px"
          label-position="left"
        >
          <el-form-item label="字段名称">
            <el-input placeholder="请输入自定义字段" v-model="formData.title" maxlength="10"  v-elInput/>
          </el-form-item>
          <el-form-item label="是否为必填项">
            <el-radio-group v-model="formData.required">
              <el-radio :label="true" size="large">是</el-radio>
              <el-radio :label="false" size="large">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否主界面列表展示">
            <el-radio-group v-model="formData.indexShow">
              <el-radio :label="true" size="large">是</el-radio>
              <el-radio :label="false" size="large">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="字段内容输入">
            <div>
              <el-radio-group v-model="formData.radio" @change="changeRadio">
                <el-radio :label="11" size="large">文本框</el-radio>
                <el-radio :label="14" size="large">下拉菜单</el-radio>
                <el-radio :label="12" size="large">单选框</el-radio>
                <el-radio :label="13"
                size="large">复选框</el-radio>
              </el-radio-group>
            </div>
            <div class="radioContenr" v-show='formData.radio != -1 '>
              <div class="item" v-if="formData.radio == 11">
                <el-input class="main" v-model="formData.text" maxlength="10" placeholder="请填写值"  v-elInput></el-input>
              </div>
              <template v-else>
                <TransitionGroup name="list">
                  <div class="item" v-for="(item, index) in addList" :key="index">
                    <div class="desc">{{ optionConfig(index + 1) }}</div>
                    <el-input v-elInput :model-value="item.value" placeholder="请填写值" maxlength="10"
                              @input="value=>item.value = value.replace(/,/g,'，')"></el-input>
                    <div class="del cur_p" v-show='index != addList.length - 1' @click='delAddList(index)'>删除</div>
                  </div>
                </TransitionGroup>
                <div class="item status">
                  <div class="desc">选项一</div>
                  <div class="addOptionBtn cur_p" @click="addOption">
                    <el-icon><Plus /></el-icon>
                    <div>添加</div>
                  </div>
                </div>
              </template>
              <!-- <div class="add_option_control">
                <el-button class="zh-btn" @click='clearAdd'>取消</el-button>
                <el-button type="primary" @click='saveDeaf'>预览</el-button>
              </div> -->
            </div>
          </el-form-item>
        </el-form>
      </div>
       <template #footer>
        <el-button @click="saveDeaf"  class="btn1 zh-btn">预览</el-button>
        <el-button class="main btn1" type="primary" @click="handleAdd"
          >保存</el-button
        >
      </template>
    </el-dialog>
    <!-- 预览样式 -->
    <el-dialog
      title="预览样式"
      v-model="showDeaf"
        width="480"
        class="add_css"
        destroy-on-close align-center :close-on-click-modal="false"
      :before-close="closeAddDiaOption"
      >
    <div class="content">
      <div class="title">
        <span class="red" v-if='deafValue.required'>*</span>{{deafValue.title}}
      </div>
      <div class="query">
         <template v-if='deafValue.radio == 11'>
              <el-input
              v-elInput
                class="queryInput w360"
                :placeholder="deafValue.list[0].value"
              ></el-input>
            </template>
            <template v-if='deafValue.radio == 12'>
               <el-radio-group class="queryInput">
                <el-radio
                  :label="item.value"
                  v-for="(item, index) in deafValue.list"
                  :key="index"
                  >{{ item.value }}</el-radio
                >
              </el-radio-group>
            </template>
            <template v-if='deafValue.radio == 13'>
               <el-checkbox-group class="queryInput">
                <el-checkbox
                  :label="item.value"
                  v-for="(item, index) in deafValue.list"
                  :key="index"
                />
              </el-checkbox-group>
            </template>
            <template v-if='deafValue.radio == 14'>
             <el-select class="queryInput" >
                <el-option
                  v-for="item in deafValue.list"
                  :key="item"
                  :label="item.value"
                  :value="item.value"
                />
              </el-select>
            </template>
      </div>
    </div>
    </el-dialog>

  </div>
</template>

<script>
import {optionConfig} from "./config";
import {Plus} from "@element-plus/icons-vue";
import {getCurrentInstance, reactive, ref, watch} from "vue";
import {customSave, customTemplate} from "@/utils/api/product/product.js";
import draggable from "vuedraggable";
import {ElMessageBox} from "element-plus";
import {cloneDeep} from 'lodash'

export default {
  components: {
    Plus,
    draggable,
  },
  props: {
    show: Boolean,
  },
  emits: ["update:show",'close'],
  setup(props, { emit }) {
    const {proxy } = getCurrentInstance()
    const showDia = ref(props.show); // 控制弹窗是否展示
    const addDia = ref(false);
    const radio = ref("1");
    const addList = ref([
      {
        name: 1,
        value: "",
      },
    ]);
    const formData = reactive({
      title: "",
      required: false,
      indexShow: false,
      radio: -1,
      upDateIndex : -1,
      text:'',
    });
    function clearData(){
      addList.value = [ {
        name: 1,
        value: "",
      },]
      formData.title = ''
      formData.required = false
      formData.indexShow = false
      formData.radio = '-1'
      formData.upDateIndex = -1
      formData.text = ''
      closeAddDiaOption()
    }
    const typeList = ref([]); // 列表类型
    const getTemplate = async () => {
      let res = await customTemplate();
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = res.data.list || []
      typeList.value = list;
    };

    const imageUrl = ref("");
    function onStart() {
    }
    function onEnd() {
    }
    const changeRadio = ()=>{
    }
    const addOption = () => {
      let length = addList.value.length
      let value = addList.value[length - 1].value
      if(!value){
        proxy.$message.error('请先填写字段名称再添加选项')
           return
      }
      for (let i = 0; i < length - 1; i++) {
        if(addList.value[i].value == value){
          proxy.$message.error('选项不能重复')
          return
        }
      }
      if(length >= 10){
        proxy.$message.error('最多添加10个选项')
        return
      }
      addList.value.push({
        name: addList.value[length - 1].name + 1,
        value: "",
      });
    };
    watch(
      () => showDia.value,
      (newValue, oldValue) => {
        if (newValue == false) {
          //重制data 数据
          emit("update:show", newValue);
        }
      }
    );
    watch(
      () => props.show,
      (newValue, oldValue) => {
        if(newValue){
          getTemplate();
        }
        showDia.value = newValue;
      }
    );
    /*
    添加字段
    */
    const addFildKey = () => {
      addDia.value = true;
    };
    const handleAdd = ()=>{
      let obj = cloneDeep(formData);
        for (let i = 0; i < typeList.value.length; i++) {
        if(typeList.value[i].title === obj.title && i !=obj.upDateIndex){
           proxy.$message.error('存在重复的字段名称')
           return
        }
      }
      if(!obj.title){
        proxy.$message.error('请填写字段名称')
        return
      }
      if(!addList.value[0].value && obj.radio != 11){
        proxy.$message.error('请完善字段内容输入')
           return
      }
      if(obj.radio == 11 && !obj.text){
        proxy.$message.error('请填写文本')
          return
      }
      obj.type = obj.radio
      if(obj.radio){
         if(obj.radio == 11){
         obj.tableValue =  formData.text
        }else{
          let list = []
          addList.value.forEach(element => {
            if(element.value){
              list.push(element.value)
            }
          });
           obj.tableValue = list.toString()
        }
      }
      clearData()
      if(obj.upDateIndex < 0){
        typeList.value.push(obj)
      }else{
        typeList.value[obj.upDateIndex] = obj
      }
      addDia.value = false;
    }
    /*
      确认保存
    */
   const handleSave = async()=>{
    let obj = cloneDeep(typeList.value)
    let maxNum = obj.reduce((a,b)=>(a.listSort || 0 )> (b.listSort||0) ? a:b).listSort
      obj.forEach((item,index) => {
          item.sort = index +1
        if(item.type >11){
          item.tableValue = item.tableValue.toString()
        }
        if(!item.listSort){
          maxNum ++
          item.listSort = maxNum
        }
      });
      let res = await customSave(obj)
       if(res.code != 0) {
          proxy.$message.error(res.msg)
          return
      }
      proxy.$message.success('保存成功')
      emit('close',{})
      showDia.value = false
   }
   //编辑
    const edit = (e)=>{
        formData.title = e.title
        formData.required = e.required
        formData.indexShow = e.indexShow
        formData.radio = e.type
        formData.upDateIndex = typeList.value.findIndex((item)=>item.title === e.title)
        if(e.type == 11){
          formData.text = e.tableValue
        }else{
          let list = e.tableValue.split(',')
          for (let i = 0; i < list.length; i++) {
            addList.value[i]={
              name:i+1,
              value:list[i]
            }
          }
        }
         addDia.value = true
   }
   const closeAddDia = ()=>{
        addDia.value =false
        emit('close',{})
        clearData()
   }
   //删除
   const del=(e)=>{
     ElMessageBox.confirm(`删除后产品信息将不再显示该字段，并清空已有字段内容`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
            let index = typeList.value.findIndex((item)=>item.fieldKey === e.fieldKey)
        typeList.value.splice(index,1)
      });
   }

   //删除新增选项
   const delAddList = (index)=>{
    addList.value.splice(index,1)
   }

   const showDeaf = ref(false)
    const deafValue = reactive({
      title:"",
      required:false,
      list:[],
      radio:0,
    })
   const saveDeaf = ()=>{
      if(!formData.title){
        proxy.$message.error('请先填写标题,然后再保存')
        return
      }
      if(!formData.radio){
        proxy.$message.error('请先选择类型,然后再保存')
        return
      }
      if(formData.radio == 11){
        if(!formData.text){
          proxy.$message.error('请先填写值,然后再保存')
          return
        }
      }else{
         if(!addList.value[0].value){
           proxy.$message.error('请先填写选项,然后再保存')
          return
        }
      }
      deafValue.title = formData.title
      deafValue.required = formData.required
      deafValue.radio = formData.radio
      deafValue.list =formData.radio == 11?[{
        name:1,
        value:formData.text
      }]:cloneDeep(addList.value)
      if(formData.radio != 11){
        if(!deafValue.list[deafValue.list.length - 1].value){
          deafValue.list.pop()
        }
      }
      showDeaf.value = true
    }
    const clearAdd = ()=>{
      addList.value = [{
        name:1,
        value:""
      }]
    }
    const closeAddDiaOption = (done) => {
      deafValue.title = ""
      deafValue.required = false
      deafValue.radio = 0
      deafValue.list = []
      showDeaf.value = false
      if (done) done()
    }
    return {
      closeAddDiaOption,
      clearAdd,
      showDeaf,
      saveDeaf,
      deafValue,
      delAddList,
      closeAddDia,
      edit,
      del,
      changeRadio,
      handleSave,
      handleAdd,
      addFildKey,
      addDia,
      showDia,
      typeList,
      radio,
      imageUrl,
      onStart,
      onEnd,
      addOption,
      addList,
      formData,
      optionConfig,
    };
  },
};
</script>
<style lang="scss" scoped>
.addGoods {
  .moreBtn {
    width: 360px;
    height: 40px;
    box-sizing: border-box;
    color: $fsColor3;
    line-height: 40px;
    display: flex;
    align-items: center;
  }
  .content {
    height: 700px;
    box-sizing: border-box;
    @include overFlow;
    .alert_text {
      font-size: 12px;
      color: $fsColor3;
      line-height: 1;
      padding: 10px 8px;
      background: #eff2ff;
    }
    padding: 0 32px;
    .diaTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 2px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }
      .btnBox {
        .el-button {
          margin-left: 16px;
          font-size: 14px;
          padding: 6px 12px;
          &.btn {
            background-color: #f0f2ff;
            color: $fsColor3;
            border: 1px solid $fsColor3;
          }
        }
      }
    }
  }
}
.add_css{
  .content{
    width: 360px;
    margin: 24px auto 60px;
    .title{
      margin-bottom: 10px;
    }
    .queryInput{
      width: 360px;
    }
  }
}

:deep(.el-alert__description) {
  margin-top: 0;
  font-weight: normal;
  .fsColor2 {
    color: $fsColor2;
  }
  .text {
    display: flex;
    align-items: center;
  }
  .alert_icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    color: #fff;
    background-color: #e54e4e;
    font-size: 12px;
    text-align: center;
    margin-right: 4px;
  }
}

:deep(.el-alert__icon) {
  width: 14px !important;
  height: 14px;
}

:deep(.el-radio__label) {
  font-size: 14px !important;
}
.query {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: 50%;
    margin-bottom: 32px;
    font-weight: 400;
    &:nth-of-type(even){
      .update_single{
        margin-left: auto;
      }
      }
    .update_single{
      border-radius: 8px;
      background: #F2F4F7;
      height: 40px;
      padding: 0 8px 0 16px;
      box-sizing: border-box;
      position: relative;

      .delImg{
        width: 16px;
        height: 16px;
        position: absolute;
        top: -8px;
        left: -8px;
      }
      .update_done{
        .update_img{
          width: 14px;
          height: 14px;
          margin-right: 16px;
        }
        .done_img{
          width: 4px;
          height: 16px;
        }
      }
    }
    .red {
      color: #e54e4e;
      position: relative;
      top: 2px;
    }


  }
}
.radioContenr {
  width: 100%;
  box-sizing: border-box;
  background-color: #f7f9fa;
  border-radius: $borderRadius8;
  padding: 24px 16px;
  .item {
    display: flex;
    align-items: center;
    color: #737480;
    margin-bottom: 16px;
    .del{
      font-size: 14px;
      color: $fsColor3;
      margin-left: 10px;
    }
    &.status {
      .desc {
        opacity: 0;
      }
    }
    .desc {
      width: max-content;
      margin-right: 12px;
    }
    .el-input {
      width: 240px;
      &.main{
        width: 100%;
      }
    }
    .addOptionBtn {
      display: flex;
      align-items: center;
      height: 36px;
      width: 240px;
      box-sizing: border-box;
      // border: 1px solid $fsColor3;
      color: $fsColor3;
      border-radius: $borderRadius8;
      padding-left: 16px;
    }
  }
  .add_option_control{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
