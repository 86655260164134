import {computed, reactive, ref ,watchEffect} from "vue";
import message from "@/utils/tool/message";
import filter from "@/utils/filter";

export default function () {
    const baseModelInfo = {
        modelId: '',
        scatteredCount:'',
        scatteredName:'',
        scattered: false,
        packing:false,
        sellPrice:'',
        sellPriceBase:'',
        buyPrice:'',
        buyPriceBase:'',
        modelName:'',
        orders: 0,
    }
    const modelInfo = reactive({...baseModelInfo})
    const  modelList = ref([])
    const isScatteredList = computed(()=>{
        return modelList.value.filter(i=>i.scattered)
    })
    const   resetModel = (item)=>{
        if(item){
            Object.assign(modelInfo, item)
            modelInfo.sellPriceBase = filter.toYuan(item.sellPrice,'元')
            modelInfo.buyPriceBase = filter.toYuan(item.buyPrice,'元')
          }else{
            Object.assign(modelInfo, baseModelInfo)
          }
    }
    const checkModel = (unit = '')=>{
        if(!modelInfo.modelName){
            message.error('请选择产品型号')
            return false
        }
        if( modelInfo.scatteredName && !modelInfo.scatteredCount){
            message.error('请输入拆零数量')
            return false
        }
        if(modelInfo.scatteredName && +modelInfo.scatteredCount < 1){
            message.error('拆零数量最低为1')
            return false
        }
        if(modelInfo.scatteredCount && +modelInfo.scatteredCount === 0 &&  !modelInfo.scatteredName){
            message.error('请输入拆零单位')
            return false
        }
        if(unit && modelInfo.scatteredName === unit){
            message.error('拆零单位不能与产品单位相同')
            return false
        }
        return true
    }

    watchEffect(()=>{
        if(modelInfo.scatteredCount && modelInfo.scatteredCount !== '0' && modelInfo.scatteredName){
            modelInfo.scattered = true
        }else{
            modelInfo.scattered = false
        }
        if (modelInfo.sellPriceBase){
            // modelInfo.sellPrice = +modelInfo.sellPriceBase * 1000
            modelInfo.sellPrice  = +modelInfo.sellPriceBase
        }
        if (modelInfo.buyPriceBase){
            // modelInfo.buyPrice = +modelInfo.buyPriceBase * 1000
            modelInfo.buyPrice  = +modelInfo.buyPriceBase
        }
    })
    return {
        modelInfo,
        modelList,
        isScatteredList,
        resetModel,
        checkModel,
    }
}