<template>
  <div class="productInformation">
    <div class="content">
       <!-- 产品信息 -->
      <el-tabs v-model="tabChecked" class="demo-tabs" @tab-click="tabCheck">
        <el-tab-pane :label="'正常（' +countInfo.normal +'）'" name="normalList">
          <NormalList v-if='tabChecked === "normalList"' @changeCount='changeCount'/>
        </el-tab-pane>
        <el-tab-pane :label="'作废（' +countInfo.discard +'）'" name="voidList">
          <VoidList v-if="tabChecked === 'voidList'" @changeCount='changeCount'/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import {getCurrentInstance, reactive, ref, toRefs} from 'vue'
import NormalList from './components/normalList';
import VoidList from './components/voidList';
import {productCensus} from '@/utils/api/product/product.js'

export default {
  name:"productInformation",
  components:{
    NormalList,VoidList
  },
  setup() {
    const tabChecked = ref('normalList')
    const { proxy} = getCurrentInstance();
    const tabCheck = function () {
      getCount();
    }
    const data = reactive({
      countInfo:{
        normal:0,
        discard:0,
      }
    })
    const changeCount = ()=>{
        getCount()
    }
       //获取数量统计
    const getCount = async () => {
      let res = await productCensus();
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      data.countInfo.normal = res.data?.normal || 0;
      data.countInfo.discard = res.data?.discard || 0;
    };
    getCount();
    return{
      tabChecked,
      tabCheck,
      ...toRefs(data),
      changeCount
    }
  },
}
</script>

<script setup>

</script>

<style lang="scss" scoped>
  .productInformation{
    height: 100%;
    padding:16px ;
    box-sizing: border-box;
    position: relative;
    >.content{
        border-radius: 16px;
        background: #fff;
        padding: 12px 32px;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
    }
  }

  :deep(.el-tabs__content) {
    height:96%;
  }

  :deep( .el-tabs) {
    height: 100%;
  }
</style>