import {stockProductIn, stockProductInfo, stockProductOut} from '@/utils/api/product/product.js'
import {objectSpanMethod} from "@/utils/tool"

export const inventoryList = [
  {
    id:0,
    title:"库存信息",
    api:stockProductInfo,
    titleConfig:[
        {
            key: "modelName",
            prop: "modelName",
            label: '规格/型号'
        },
      {
          key: "scatteredName",
          prop: "scatteredName",
        label: '产品单位'
      },
      {
        key:"inventoryQuantity",
        prop:"inventoryQuantity",
        label:'库存数量'
      },
      {
        key:"price",
        prop:"price",
        label: '单价（元）'
      },
      {
        key:"totalPrice",
        prop:"totalPrice",
        label: '合计金额（元）'
      },
    ],
    objectSpanMethod: objectSpanMethod([0])
  },
  {
    id:1,
    title:"入库信息",
    api:stockProductIn,
    titleConfig:[
      {
        key:"createTime",
        prop:"createTime",
          label: '入库时间'
      },
      {
          key: "scatteredName",
          prop: "scatteredName",
          label: '产品单位'
      },
      {
        key:"modelName",
        prop:"modelName",
        label:'规格/型号'
      },
      {
        key:"count",
        prop:"count",
        label: '入库数量'
      },
      {
        key:"price",
        prop:"price",
        label: '单价（元）'
      },
      {
        key:"totalPrice",
        prop:"totalPrice",
        label: '合计金额（元）'
      },
    ],
    objectSpanMethod: null
  },
  {
    id:2,
    title:"销售信息",
    api:stockProductOut,
    titleConfig:[
      {
        key:"createTime",
        prop:"createTime",
          label: '销售时间'
      },
      {
          key: "scatteredName",
          prop: "scatteredName",
          label: '产品单位'
      },
      {
        key:"modelName",
        prop:"modelName",
        label:'规格/型号'
      },
      {
        key:"count",
        prop:"count",
        label: '出库数量'
      },
      {
        key:"price",
        prop:"price",
        label: '单价（元）'
      },
      {
        key:"totalPrice",
        prop:"totalPrice",
        label: '合计金额（元）'
      },
    ],
    objectSpanMethod:null
  },
]