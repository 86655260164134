<template>
<!-- 箱单关系 -->
  <el-dialog
    v-bind="$attrs"
    v-model="showPacking"
    class="packing_modal"
    :align-center='true'
    title="箱单关系"
    width="1400"
    :close-on-click-modal="false"
    destroy-on-close
    @open="isActive"
  >
  <el-scrollbar>
    <!-- <div class="packing_list" :style="{'maxHeight':packingList.length * 200 +'px'}"> -->
    <div class="packing_list" >
      <template v-if="packingList && packingList.length > 0">
          <div class="packing_item" v-for="(item,index) in packingList" :key="index">
            <template v-if='item && item.length >0'>
                <div class="packing_single" :class="item1.active?'active':''" v-for="(item1,index1) in item" :key="item1.modelId">
          <div class="packing_line" v-if='index1 != 0'>
              <span class="packing_count">1{{item[index1 - 1]?.unitName||'-'}}</span>
              <span class="packing_text">=</span>
              <span class="packing_count">{{item1.count}}{{item1.unitName}}</span>
          </div>
          <div class="packing_content">
            <div class="packing_name">{{item1.productName}}</div>
            <div class="packing_unit">单位:{{item1.unitName}}</div>
            <el-tooltip :content="item1.modelName" placement="bottom" effect="light">
              <div class="packing_model packing_desc">规格型号:{{item1.modelName}}</div>
            </el-tooltip>
            <div class="packing_encode packing_desc">
              <div class="text">条码:</div><more-list :list='item1.encoded'></more-list></div>
          </div>
        </div>
            </template>
      </div>
      </template>
      <template v-else>
         <div class="packing_empty">
            <div class="packing_empty_text">
                暂无箱单关系
            </div>
            <div class="goAdd btn" @click='goAdd'>去添加</div>
          </div>
      </template>
    </div>
  </el-scrollbar>
  </el-dialog>
<!-- 箱单关系  end-->
</template>

<script setup>
import MoreList from '@/components/moreList/moreListshort.vue';
import {defineProps, ref, toRefs} from 'vue'
import {useRouter} from 'vue-router'

const props = defineProps({
  productId: {
    type: Number,
    default: 0
  },
  packingList: {
    type: Array,
    default: []
  },
  modelList: {
    type: Array,
    default: []
  }
})
const {productId,packingList,modelList} = toRefs(props) // 传入的商品id
const showPacking = ref(false)
const router = useRouter()
const goAdd = () => {
  router.push({
    path: '/product/packingList',
    query: {
      productId: productId.value
    }
  })
}

const clineWidth = ref('')
const getWidth = ()=>{
  let maxNum = 0;
  packingList.value.forEach(item=>{
    if(item.length > maxNum){
      maxNum = item.length
    }
  })
  clineWidth.value = maxNum
}

const isActive = ()=>{
  if(packingList.value?.length > 0){
    packingList.value.forEach(item=>{
      item.forEach(item1=>{
        if(modelList.value?.length > 0){
          modelList.value.forEach(item2=>{
            if(item1.modelId == item2.modelId){
              item1.active = true
            }
          })
        }
      })
    })
  }
  getWidth()
  showPacking.value = true
}

defineExpose({
  isActive
})
</script>

<style lang="scss">
@import './packing.scss';

</style>