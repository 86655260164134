<template>
  <el-button type="primary" v-bind='attrs'>
    <el-icon>
      <Plus/>
    </el-icon>
    <span class="text">新增</span>
  </el-button>
</template>

<script setup>
import {Plus} from "@element-plus/icons-vue";
import {useAttrs} from "vue";

const attrs = useAttrs()
</script>

<style lang="scss" scoped>

</style>