<template>
  <span class="add-model-btn cur_p" v-if="!props.update" @click="openModal()"
    >添加</span
  >
  <img
    src="@/assets/img/index/updateImg.png "
    @click="openModal(props.modelName)"
    v-else
    class="ml_16 cur_p img"
  />
  <!-- 新增品牌弹窗 -->
  <el-dialog
    v-model="showModal"
    title="规格/型号"
    width="500"
    :align-center="true"
  >
    <el-form label-width="100px" style="max-width: 460px">
      <el-form-item label="规格型号" required>
        <el-input
          placeholder="请输入规格型号"
          maxlength="40"
          v-model="modelInfo.modelName"
        />
      </el-form-item>
      <el-form-item label="采购价" >
        <el-input
          placeholder="请输入采购价格"
          maxlength="10"
          @blur="modelInfo.buyPriceBase = +$event.target.value"
          v-number.fixed
          v-model="modelInfo.buyPriceBase"
        />
      </el-form-item>
      <el-form-item label="销售价" >
        <el-input
          placeholder="请输入销售价"
          maxlength="10"
          @blur="modelInfo.sellPriceBase = +$event.target.value"
          v-number.fixed
          v-model="modelInfo.sellPriceBase"
        />
      </el-form-item>
      <el-form-item label="拆零">
        <div class="form dp_f">
          <div class="unit">
            1
            <OverFlowText
              :value="props.unit || modelInfo.unit || '产品单位'"
              max-length="5"
              class="span"
            ></OverFlowText>
            =
          </div>
          <el-input
            class="model-input mr_10"
            v-elInput
            v-model="modelInfo.scatteredCount"
            @blur="modelInfo.scatteredCount = $event.target.value"
            v-number.fixed
            placeholder="如：10"
            maxlength="10"
          ></el-input>
          <el-input
            class="model-input"
            v-elInput
            v-model="modelInfo.scatteredName"
            placeholder="如：件"
            maxlength="5"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="handleCloseModal">取消</el-button>
      <el-button class="main" type="primary" @click="handleSave"
        >确认</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, computed } from "vue";
import { useVModel } from "@vueuse/core";
import OverFlowText from "@components/overFlowText/index.vue";
import useModal from "@utils/hooks/useModal";
import useProductModel from "@utils/tool/product/useProductModel";
import message from "@/utils/tool/message";

const { modelInfo, resetModel, checkModel } = useProductModel();
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  modelName: {
    type: [String, Number],
    default: "",
  },
  unit: {
    type: String,
    default: "",
  },
  update: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
const modelValue = useVModel(props, "modelValue", emit);
const { showModal, handleOpenModal, handleCloseModal } = useModal();
//打开弹窗
const openModal = (name) => {
  if (!name) {
    resetModel();
  } else {
    const item = modelValue.value.find((item) => item.modelName === name);
    resetModel(item);
  }
  handleOpenModal();
};

//确认保存规格
const handleSave = () => {
  if (!checkModel(props.unit)) {
    return;
  }
  if (props.update) {
    const newArr = modelValue.value.filter(item => item.modelId !== modelInfo.modelId);
    if (
      newArr.findIndex(
      (item) => item.modelName === modelInfo.modelName
    ) !== -1
  ) {
    message.error("该规格型号已存在");
    return;
  }
    const index = modelValue.value.findIndex(
      (item) => item.modelId === modelInfo.modelId
    );
    modelValue.value[index] = { ...modelInfo, unit: props.unit };
  }else{
    if (
    modelValue.value.findIndex(
      (item) => item.modelName === modelInfo.modelName
    ) !== -1
  ) {
    message.error("该规格型号已存在");
    return;
  }
 modelValue.value = [...modelValue.value, { ...modelInfo, unit: props.unit }];
  }
  handleCloseModal();
};
</script>

<style lang="scss" scoped>
.add-model-btn {
  color: $mainColor;
}
.img {
  width: 14px;
  height: 14px;
}
.form {
  justify-content: flex-start !important;
  flex-wrap: wrap;
  > * {
    margin-bottom: 10px;
  }
  .model-input {
    width: 100px;
  }
  .unit {
    font-size: 14px;
    font-weight: bold;
    margin: 0 8px 12px;
    :deep(.span) {
      margin: 0 6px;
    }
  }
}
</style>
