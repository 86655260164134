<template>
 <el-tooltip
      effect="dark"
      :content="props.value"
      placement="top-start"
    >
      <span v-bind="attrs" class="text">{{ showText }}</span>
    </el-tooltip>
</template>

<script setup>
import { computed ,useAttrs} from 'vue';
const props = defineProps({
  value:{
    type: String,
    default: ''
  },
  maxLength:{
    type: String,
    default: '5'
  }
})

const attrs = useAttrs()
const showText = computed(()=>{
  if(!props.value) return ''
  if(props.value.length > props.maxLength){
    return props.value.slice(0,props.maxLength) + '...'
  }
  return props.value
})
</script>

<style lang="scss" scoped>
 .text{
    user-select: none;
  }
</style>