<template>
  <div>
    <el-dialog
        v-model="showDia"
        :title="titleStr"
        width="48%"
        class="abcCheck"
      >
      <div class="content">
        <div class="query">
          <div class="queryBox">
            <div class="text">{{titleStr}}</div>
            <el-input :placeholder="'请输入'+unitStr" class="w360" v-model="formData.name" v-elInput></el-input>
            <el-button type="primary" @click='search'
              ><el-icon><Search /></el-icon
            ></el-button>
          </div>
          <div class="controlBox" v-if="updateStatus">
            <el-button type="primary" @click='add'> <el-icon><Plus /></el-icon><span class="text">新增</span></el-button>
            <el-button type="primary" class="ml10 btn" @click="handleUpdate">{{isUpdate?'完成':'编辑' }}</el-button>
          </div>
        </div>

        <div class="selectBox">
          <div class="item cur_p" :class="!checkList.length?'active':''" @click='checkAbc()'>所有{{unitStr}}</div>
          <div v-for="item in abcList" :key="item" class="item cur_p" :class="$filters.hasStr(item,checkList)?'active':''" @click='checkAbc(item)'>{{item}}</div>
        </div>
        <div class="info">
          <div class="single" v-for="(item,index) in menuList" :key="index">
            <div v-if='item.list.length > 0 && (!checkList.length || checkList[0] == item.prefix.toUpperCase())'>
              <div class="title">{{item.prefix}}</div>
            <div class="tagList" v-if='!isUpdate'  >
              <div class="tags" v-for="(item1,index1) in item.list" :key="index1" :class="$filters.objHasStr('name',item1.name,itemCheckedList)?'active':''" @click="checkItem(item1)">{{item1.name}}</div>
            </div>
            <div class="tagList" v-if='isUpdate'>
              <div class="tags" v-for="item1 in item.list" :key="item1"  @click="deleteName(item1)">{{item1.name}}
                <img src="@/assets/img/index/delete.png"  class="del">
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
          <el-button @click="showDia = false">取消</el-button>
          <el-button class="main" type="primary" @click="handleOk">
            确认
          </el-button>
      </template>
    </el-dialog>
    <!-- 新增品牌弹窗 -->
    <el-dialog  v-model="showAddDia"
        :title="titleStr"
        width="30%"
        :align-center='true'
        class="abcCheck">
          <el-form
          label-width="150px"
          :model="addFormData"
          style="max-width: 460px"
          :rules="addRules"
        >
          <el-form-item :label="titleStr" prop="typeName">
            <el-input :placeholder="titleStr" v-model="addFormData.typeName" />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="showAddDia = false">取消</el-button>
          <el-button class="main" type="primary" @click="handleOkAdd">确认</el-button>
         </template>
        </el-dialog>
  </div>
</template>


<script>
import { reactive, toRefs , ref ,onMounted, toRaw,getCurrentInstance ,watch } from "vue";
import {  Search,Plus } from "@element-plus/icons-vue";
import { abcList } from './config';
export default {
  components:{
    Search,
    Plus
  },
  props: {
    list:Array,
    title:String,
    unit:String,
    maxNum:Number,
    show:Boolean,
    update:Boolean,
  },
  setup(props,{emit}) {
    const { proxy } = getCurrentInstance()
    const titleStr = ref(props.title)
    const unitStr = ref(props.unit)
    const fieldsCheck=ref(null)
    const showDia = ref(props.show)
    const menuList = ref(props.list)
    const updateStatus = ref(props.update)
    const checkList = ref([])
    const itemCheckedList = ref([]) // 品牌选中列表
    const showAddDia = ref(false) // 新增品牌弹窗
    const isUpdate = ref(false) //判断是否修改状态
    const handleUpdate = ()=>{
      isUpdate.value = !isUpdate.value
    }
    const deleteName = (e)=>{
      menuList.value.forEach((item)=>{
        for (let i = 0; i < item.list.length; i++) {
          if(item.list[i].name == e.name){
              item.list.splice(i,1)
              emit('del',e)
              return
          }
        }
      })
    }
    const formData = reactive({
      name:''
    })
    const addFormData = reactive({
      typeName:'',//新增品牌名称
    })
    const addRules = {
      typeName: [
        { required: true, message: '请先填写数据' }
      ],
    }
    const handleOk = ()=>{
      emit('handleOk',itemCheckedList.value)
     showDia.value = false
    }
    onMounted(()=>{
      //设置数组选中状态
    })
    const checkAbc = (item)=>{
        if(!item){
          checkList.value = []
          return
        }
        if(checkList.value.includes(item)){
          checkList.value = []
          return
        }
        checkList.value = [item]
    }
    const checkItem = (item)=>{
      if(itemCheckedList.value.findIndex((e)=>{return e == item}) != -1){
        let list = toRaw(itemCheckedList.value)
        let index = list.findIndex((e)=> e === item)
        itemCheckedList.value.splice(index,1)
        return
      }
      //选择品牌
      if(itemCheckedList.value.length >= props.maxNum){
        itemCheckedList.value.splice(0,1)
        itemCheckedList.value.push(item)
        return
      }
       itemCheckedList.value.push(item)
    }
    const handleClickBtn = ()=>{
      showDia.value = !showDia.value
    }
    const search = ()=>{
      emit('search',formData.name)
    }
    const add = ()=>{
      showAddDia.value = true
    }
    //确认新增品牌
    const handleOkAdd = async ()=>{
      if(!addFormData.typeName){
         proxy.$message.error('请填写完成在确认')
         return
      }
       emit('add',addFormData.typeName)
       addFormData.typeName = ''
       showAddDia.value = false
    }
    //监听显示影藏
    watch(
      () => props.show,
      (newValue, oldValue) =>{
      showDia.value = newValue
      isUpdate.value = false
    })
    //监听列表数据变化
    watch(
      () => props.list,
      (newValue, oldValue) =>{
      menuList.value = newValue
    })
    watch(() => showDia.value,(newValue)=>{
      emit('close',newValue)
    })
    return {
      showDia,
      menuList,
      checkList,
      handleOk,
      fieldsCheck,
      handleClickBtn,
      abcList,
      titleStr,
      unitStr,
      search,
      checkAbc,
      itemCheckedList,
      checkItem,
      formData,
      showAddDia,
      add,
      addFormData,
      addRules,
      isUpdate,
      handleUpdate,
      deleteName,
      updateStatus,
      handleOkAdd
    };
  },
};
</script>
<style lang="scss" scoped>
  .abcCheck{
    .content{
      .query{
        display: flex;
        justify-content: space-between;
        align-content: center;
        .btn{
            background-color: #F0F2FF;
            color: $fsColor3;
            border: 1px solid $fsColor3;
        }
      }
      .queryBox{
        display: flex;
        align-items: center;
        .text{
          color: $fsColor2;
          margin-right: 8px;
        }
        .w360{
          margin-right: 16px;
        }
      }
      .selectBox{
        margin-top: 14px;
        display: flex;
        flex-wrap: wrap;
        .item{
          height: 24px;
          line-height: 24px;
          padding: 0 8px;
          box-sizing: border-box;
          margin-right: 4px;
          font-weight: normal;
          border-radius: 4px;
          border: 1px solid #fff;
          margin-bottom: 8px;
          font-size: 12px;
          &.active{
            border: 1px solid $fsColor3;
            color: $fsColor3;
          }
        }
      }
      .info{
        height: 500px;
        overflow-y: auto;
       @include overFlow();
        margin-top: 24px;
        .single{
          font-weight: normal;
          .title{
            font-size: 12px;
            color:#AAAAB2;
            line-height: 1;
          }
          .tagList{
            margin-top: 8px;
            .tags{
              margin-right: 16px;
              margin-bottom: 26px;
              border: 1px solid #DCDEE0;;
              color: #737480;
              position: relative;
              .del{
                width: 16px;
                height: 16px;
                position: absolute;
                top: -8px;
                right: -8px;
              }
              &.active{
                color: #fff;
                background-color: $fsColor3;
                border: 1px solid $fsColor3;
              }
            }
          }
        }
      }
    }
  }
</style>
