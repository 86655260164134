<template>
  <el-scrollbar height="148px" always>
    <div class="zh-goods-info-list" v-if="listData">
      <template v-for="item in listData" :key="listData.fieldKey">
        <!--  规格型号  -->
        <div class="singles" v-if="item.fieldKey !== 'model'" :class="{last:item.last}">
          <el-scrollbar height="148px">
            <template v-if="item.fieldKey === 'encoded'">
              <div class="info-title">{{ item.title }}</div>
              <template v-if="item?.value?.length">
                <div class="info-value model-list" v-for="i in item.value" :key="i">{{ i }}</div>
              </template>
            </template>
            <template v-else>
              <div class="info-title" v-if="item.title">{{ item.title }}</div>
              <div class="info-value" v-if="item.title">{{ item.value || '暂无' }}</div>
            </template>
          </el-scrollbar>
        </div>
        <div class="singles modelList" v-else>
          <el-scrollbar height="148px">
            <div class="info-title">{{ item.title }}</div>
            <div class="info-value model-list singleModel" :class="{'model-discard' : i.discard}" v-for="i in item.value" :key="i">
             <div class="item">{{ i.modelName }}{{ i.discard ? '（已作废）' : '' }}</div>
             <div class="item">采购价（元）：{{ filter.toYuan(i.buyPrice,'元')|| '--' }}</div>
             <div class="item">销售价（元）：{{ filter.toYuan(i.sellPrice,'元') || '--' }}</div>
             <div class="item" v-show="i.scattered">拆零：1{{ unitName }}={{ i.scatteredCount  }}{{ i.scatteredName }}</div>
            </div>
          </el-scrollbar>
        </div>
      </template>
    </div>
  </el-scrollbar>
</template>

<script setup>
import {computed, ref, toRefs, watch} from "vue";
import filter from "@/utils/filter";
const props = defineProps({
  list: {
    type: Array,
    default: () => []
  },
  unitName:{
    type:String,
    default:''
  }
});
const {list , unitName} = toRefs(props)
const listData = ref([])
const setScatteredList = (newVal)=>{
  let model = newVal.find(item=>item.fieldKey === 'model')
  let scattered = model.value.filter(item=>item.scattered)
  if(!scattered.length){
    return
  }
  newVal.push({
    title: '拆零',
    fieldKey: 'scattered',
    value: scattered,
  })
}
watch(
    () => list.value,
    (val) => {
      if (val?.length === 0) {
        return
      }
      const newVal = JSON.parse(JSON.stringify(val))
      // setScatteredList(newVal)
      //val的长度除5取余数
      newVal.forEach((item, index) => {
        item.isLast = (index+1) % 5 === 0
      })
      listData.value = newVal
    }, {
      deep: true
    }
);
</script>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'GoodsMoreList',
  inheritAttrs: false,
})
</script>

<style lang="scss" src="./index.scss" scoped></style>