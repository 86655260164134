<template>
  <el-dialog
    v-bind="$attrs"
    class="goodsInfoModal"
    :align-center="true"
    title="详情"
    width="1400"
    :close-on-click-modal="false"
    destroy-on-close
    @opened="openDiaDetail"
    @close="closeModal"
  >
    <div class="content">
      <el-scrollbar
        :style="{ height: `calc( ${height}px - 200px)`, padding: '0 32px' }"
        always
      >
        <div class="diaTitle">
          <div class="title">基础信息</div>
          <div class="btnBox" v-if="typeName === 'normal'">
            <!-- <el-button
              type="primary"
              class="zh_btn2"
              v-if="showPacking"
              @click="openPackging"
              >箱单关系</el-button
            > -->
            <el-button class="zh-btn zh_btn2" @click="checkGoods"
              >编辑</el-button
            >
            <el-button class="zh-btn zh_btn2" @click="copyGood">复制</el-button>
            <el-button class="zh-btn zh_btn2" @click="delGoods">作废</el-button>
          </div>
          <div class="btnBox" v-if="typeName === 'void'">
            <!-- <el-button type="primary" @click="openPackging" v-if="showPacking"
              >箱单关系</el-button
            > -->
            <el-button class="zh-btn" @click="resumeGood">恢复使用</el-button>
          </div>
          <div class="btnBox" v-if="typeName === 'rfid' && showPacking">
            <!-- <el-button type="primary" @click="openPackging">箱单关系</el-button> -->
          </div>
        </div>
        <!-- 商品信息 -->
        <div class="zh-goods-info">
          <div class="goods_description dp">
            <good-img :src="goodsInfo.img"></good-img>
            <div class="goods-description-right">
              <div class="goods-name">
                <div class="name">{{ goodsInfo.productName || "--" }}</div>
                <div>
                  {{ goodsInfo.subName ? `（${goodsInfo.subName}）` : "" }}
                </div>
                <div class="split">/</div>
                <div class="unit">{{ goodsInfo.unitName || "--" }}</div>
              </div>
              <div class="goods-description-more dp_f">
                <div class="goods-description-more-single">
                  <div class="goods-description-more-title">产品分类：</div>
                  <show-more
                    class="goods-description-more-content"
                    :text="goodsInfo.classifyName || '--'"
                  ></show-more>
                </div>
                <div class="goods-description-more-single">
                  <div class="goods-description-more-title">产品品牌：</div>
                  <show-more
                    class="goods-description-more-content"
                    :text="goodsInfo.brandName || '--'"
                  ></show-more>
                </div>
              </div>
            </div>
          </div>
          <goods-more-list
            :list="goodsInfo.list"
            :unit-name="goodsInfo.unitName"
          ></goods-more-list>
        </div>
        <!-- 商品信息 end-->
        <div class="diaTitle mt_20">
          <div class="title">其他信息</div>
        </div>
        <!-- 其他信息 -->
        <div class="choose-model">
          <div class="text">选择规格/型号</div>
          <div class="select-list" v-if="modelList.length > 0">
            <el-button
              :class="!tableModelChecked && 'zh-btn'"
              plain
              @click="tableModelCheck('')"
              >全部</el-button
            >
            <template v-for="item in modelList" :key="item.modelId">
              <el-button
                v-if="!item.discard"
                :class="item.modelId === tableModelChecked ? 'zh-btn' : ''"
                plain
                @click="tableModelCheck(item.modelId)"
                >{{ item.modelName }}</el-button
              >
            </template>
          </div>
        </div>
        <!--        最近采购价-->
        <!--        <div class="over-price mt_16">-->
        <!--          <div class="item" v-for="item in productPriceList">-->
        <!--            <div class="title">{{ item.title }}：</div>-->
        <!--            <div class="price">-->
        <!--              {{ item.price ? $filters.toYuan(item.price, "元") : "&#45;&#45;" }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="inventoryList">
          <div class="tab">
            <el-tabs
              v-model="tabChecked"
              class="zh-tabs"
              @tab-change="tabCheck"
            >
              <el-tab-pane
                class="zh_tabs_pane"
                v-for="(item, index) in inventoryList"
                :key="item.title"
                :name="index"
                :label="item.title"
              >
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="zh_carousel" v-loading="loading">
            <div class="goodsInfo_table_list">
              <el-table
                :data="tableData"
                style="width: 100%"
                class="zh-no-table-border"
                :height="height - 760 < 300 ? 300 : height - 760"
                :row-class-name="tableRowClassNameNew"
                :span-method="inventoryList[tabChecked].objectSpanMethod"
                :cell-style="{ border: 'none' }"
                :header-row-style="{ border: 'none' }"
              >
                <template
                  v-for="item in inventoryList[tabChecked].titleConfig"
                  :key="item.key"
                >
                  <el-table-column
                    align="center"
                    v-if="item.key === 'scatteredName'"
                    :label="item.label"
                    show-overflow-tooltip
                  >
                    <template #default="scope">
                      <div
                        v-html="
                          getUnitName(scope.row)
                        "
                      ></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      align="center"
                      v-else-if="item.key === 'price'"
                      :label="item.label"
                      show-overflow-tooltip
                  >
                    <template #default="scope">
                      <span>{{ scope.row.price === '0.00' ? '--' : scope.row.price }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    v-else
                    :label="item.label"
                    show-overflow-tooltip
                  >
                    <template #default="scope">
                      <div v-html="scope.row[item.prop]"></div>
                    </template>
                  </el-table-column>
                </template>
                <template #empty>
                  <WyEmpty/>
                </template>
              </el-table>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <!-- 库存列表 -->
      <!-- 库存列表end -->
    </div>
    <Packging
      ref="packgingVisible"
      :productId="goodsInfo.productId"
      :packingList="packingList"
      :modelList="goodsInfo.model"
    ></Packging>
    <!-- 编辑产品弹窗 -->
    <ChangeGoods
      ref="changeGoodsDia"
      @ok="okChangeGoods"
      v-model:goodsInfo="chooseGoods"
    ></ChangeGoods>
  </el-dialog>
</template>
<script setup>
//引入 编辑产品组件
import ChangeGoods from "./changeGoods.vue";
import GoodImg from "@components/goodImg/index.vue";
import ShowMore from "@components/common/showMore/index.vue";
import GoodsMoreList from "@components/goods/goodsInfoModal/component/goodsMoreList/index.vue";
import {productDiscard, productPacking, productResume,} from "@/utils/api/product/product.js";
//引入 库存列表接口
//引入vue
import {computed, defineProps, getCurrentInstance, reactive, ref, toRefs, useAttrs, watch,} from "vue";
import {useStore} from "vuex";
//引入reactive对象重置
//设置模型
import {reactiveAssign, resetGoodsList, tableRowClassNameNew, treeToArray,} from "@/utils/tool.js";
import {ElMessageBox} from "element-plus";
import Packging from "./component/packing.vue";
import {cloneDeep} from "lodash";
import {getGoodsDetails} from "./util";
import {useViewPort} from "@utils/tool/useVIewPort";
import {inventoryList} from "./config";
import {useProductPrice} from "@utils/tool/product/useProductPrice";
import {getUnitName} from "@utils/tool/orderTools";

import WyEmpty from "@components/element/empty/index.vue";

const { productPriceList, getProductPriceList } = useProductPrice();
const { height } = useViewPort();
const loading = ref(false);
const props = defineProps({
  typeName: {
    type: String,
    default: "normal",
  },
});
const { typeName } = toRefs(props);
const store = useStore();
const { proxy } = getCurrentInstance();
const goodsInfo = reactive({
  sellPrice: [],
  buyPrice: [],
  avgBuyPrice: "",
  avgSellPrice: "",
  list: [],
});
const tabChecked = ref(0);
const tableModelChecked = ref("");
const tableData = ref([]);
//获取库存列表
const getGoodsList = () => {
  loading.value = true;
  inventoryList[tabChecked.value]
    .api({
      productId: goodsInfo.productId,
      modelId: tableModelChecked.value,
    })
    .then((res) => {
      if (res.code === 0) {
        let list = res.data?.list || [];
          list = setList(list);
          tableData.value = list;
      }
    })
    .finally(() => {
      loading.value = false;
    });
};
//判断是否存在拆零关系
const checkScattered = (modelId) => {
  if (!goodsInfo.model) return false;
  if (modelId === "") return true;
  return (
    goodsInfo.model.findIndex((i) => i.modelId === modelId && i.scattered) > -1
  );
};

//库存信息合并列表
const mergeList = (list = []) => {
  let map = new Map();
  const newList = []
  list.forEach(item => {
    const key = item.modelId + item.price
    if (map.has(key)) {
      let obj = map.get(key);
      obj.totalPrice = obj.totalPrice + item.totalPrice;
      obj.inventoryQuantity = obj.inventoryQuantity + item.inventoryQuantity;
      map.set(key, obj)
    } else {
      map.set(key, item)
    }
  })
  for (let [key, value] of map) {
    newList.push(value)
  }
  return newList
}
const setList = (list) => {
  if(tabChecked.value === 0){
    let newList = mergeList(list)
    return resetGoodsList(newList, (item) => {
        item.price = proxy.$filters.toYuan(item.price, "元");
        item.totalPrice = proxy.$filters.toYuan(item.totalPrice, "元");
    });
  }else{
    return list.map((item) => {
      item.price = proxy.$filters.toYuan(item.price, "元");
      item.totalPrice = proxy.$filters.toYuan(item.totalPrice, "元");
      item.createTime = proxy.$filters.timeFormat(item.createTime, 1);
      return {...item};
    });
  }

};
//箱单关系弹窗
const packgingVisible = ref(null);
const packingList = ref([]);

//控制编辑产品弹窗
const changeGoodsDia = ref(null);
const chooseGoods = ref({});
const checkGoods = () => {
  chooseGoods.value = cloneDeep(goodsInfo);
  changeGoodsDia.value.openDia();
};

const okChangeGoods = () => {
  getGoodsDetails(goodsInfo, (e) => {
    reactiveAssign(goodsInfo, e);
    delOtherList();
    if (goodsInfo.model && goodsInfo.model.length > 0) {
    modelList.value = cloneDeep(goodsInfo.model);
    getGoodsList();
    getProductPriceList({
      productId: goodsInfo.productId,
    });
  }
  });
};

//打开箱单关系弹窗
const openPackging = () => {
  productPacking({ productId: goodsInfo.productId }).then((res) => {
    if (res.code === 0) {
      let list = res.data?.list || [];
      packingList.value = treeToArray(list, "child");
      packgingVisible.value.isActive();
      return;
    }
    proxy.$message.error(res.msg);
  });
};
//是否展示箱单关系
const showPacking = computed(() => {
  if (!goodsInfo.model?.length) return false;
  return goodsInfo.model?.findIndex((item) => !item.scattered) !== -1;
});
/*
作废产品
*/
const delGoods = async () => {
  ElMessageBox.confirm("该产品是否确认作废，不再使用！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    productDiscard({
      productId: goodsInfo.productId,
    }).then((res) => {
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      proxy.$message.success("作废成功");
      proxy.$emit("close");
    });
  });
};

//恢复箱单关系
const resumeGood = async () => {
  ElMessageBox.confirm("确认恢复产品使用？", "恢复", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    productResume({
      productId: goodsInfo.productId,
    }).then((res) => {
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      proxy.$message.success("恢复成功");
      proxy.$emit("close");
    });
  });
};

const tableModelCheck = (e) => {
  if (e === tableModelChecked.value) return;
  tableModelChecked.value = e;
  getGoodsList();
  getProductPriceList({
    productId: goodsInfo.productId,
    modelId: tableModelChecked.value,
  });
};

//库存列表切换
const tabCheck = () => {
  getGoodsList();
};
const copyGood = () => {
  proxy.$emit("copyGood", goodsInfo);
};
const closeModal = () => {
  store.commit("GOODS_INFO", {});
};
const modelList = ref([]);
const openDiaDetail = () => {
  reactiveAssign(goodsInfo, store.state.goodsInfo);
  delOtherList();
  if (goodsInfo.model && goodsInfo.model.length > 0) {
    modelList.value = cloneDeep(goodsInfo.model);
    getGoodsList();
    getProductPriceList({
      productId: goodsInfo.productId,
    });
  }
};

//删除goodsInfo.list里面多余的字段
function delOtherList() {
  goodsInfo.list = goodsInfo.list.filter((item) => {
    return (
      item.fieldKey !== "img" &&
      item.fieldKey !== "productName" &&
      item.fieldKey !== "unitName" &&
      item.fieldKey !== "classifyName" &&
      item.fieldKey !== "brandName" &&
      item.fieldKey !== "subName"
    );
  });
}

//规格价格
const getModelPrice = () => {};
</script>
<script>
import { ref, getCurrentInstance, watch, useAttrs } from "vue";
import { Search, Plus } from "@element-plus/icons-vue";
import { cloneDeep } from "lodash";
export default {
  name: "goodsInfoModal",
  components: {
    Search,
    Plus,
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
