// 上传oos fn manage2.0
import axios from 'axios';
import {ElMessage} from 'element-plus'
import {compressAccurately} from 'image-conversion'

// 获取文件名后缀
function get_suffix(filename) {
  let pos = filename.lastIndexOf('.');
  let suffix = '';
  if (pos != -1) {
      suffix = filename.substring(pos);
  }
  return suffix;
}
function uploadFile(url,file,maxSize = 300) {
  //manxSize 单位 kb
  return new Promise((resolve, reject) => {
    // 获取服务端签名
    axios.get(url).then((res) => {
      if (res.code === 0) { // 签名成功
        let data = res.data;
        const { accessid, dir, expire, fileName, host, path, policy, signature } = data;
        let formData = new FormData();

        let obj = {
          'name' : fileName + get_suffix(file.name), // 文件名
          'key': dir+fileName + get_suffix(file.name), // 路径+文件名
          'policy': policy, // 配置
          'OSSAccessKeyId': accessid,
          'success_action_status' : '200', //让服务端返回200,不然，默认会返回204
          'signature': signature,
          'callback': '',
        }
        for (let key in obj) {
          formData.append(key, obj[key]);
        }
        if(file.size > maxSize * 1024 ) {
          compressAccurately(file, maxSize).then(res => {
            formData.append('file', res);
             // 上传oos
            axios.post(host, formData).then((res) => {
              let url = data.path + data.dir + data.fileName + get_suffix(file.name);
              let name = fileName + get_suffix(file.name);
              resolve({url,name});
            }).catch(err => {
              upL
              reject(err);
            })
          })
        }else{
          formData.append('file', file);
           // 上传oos
          axios.post(host, formData).then((res) => {
            let url = data.path + data.dir + data.fileName + get_suffix(file.name);
            let name = fileName + get_suffix(file.name);
            resolve({url,name});
          }).catch(err => {
            reject(err);
          })
        }

      } else {
        ElMessage.error(res.msg);
        resolve(res);
      }
    }).catch(err => {
      reject(err);
    })
  })
}


export default uploadFile;