import {isEqual} from 'lodash';
//复制文本内容到剪切板
 export const copyToClipboard = (text)=> navigator.clipboard.writeText(text)
//获取某个日期位于当年的第几天
export const dayOfYear = (date)=> Math.floor(date - new Date(date.getFullYear(),0,0)) / 1000 / 60 / 60 / 24
//rgb颜色灰度化
export const gray = (r,g,b) => 0.2126 * r + 0.7152 * g + 0.0722 * b;
//获取url 中的参数
export const parseQuery =( url = '') =>{
    const q = {}
    url.replace(/([^?&=]+)=([^&]+)/g,(_,k,v)=>(q[k] = v))
    return q
}
//筛选对象属性
const pick = (obj,...props) => Object.fromEntries(Object.entries(obj).filter(([k])=>props.includes(k)))
//随机颜色
export  const randomColor = ()=>{
    return (
        '#' + Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6,'0')
    )
}
//去掉字符串的标签
export  const removeTags = (html = '')=> new DOMParser().parseFromString(html,'text/html').body.textContent || ''
//返回两个列表新增和删除的元素
export const diffProduct = (arr1,arr2)=>{
    const map = new Map()
    arr1.forEach(item=>{
        map.set(item.modelId,item)
    })
    const addModel =  arr2.filter(v=>{
        return !v.modelId
    })
    const changeModel = arr2.filter(v=>{
        if(map.has(v.modelId)){
            let item = map.get(v.modelId)
            return !isEqual(item,v)
        }
    })
     return {
        addModel,
        changeModel
    }
}