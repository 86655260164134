<template>
   <el-dialog
        v-model="showDia"
        title="展示项设置"
        width="60%"
        class="checkModal"
        @close="closeDia"
      >
      <div class="fieldsContent">
        <el-checkbox-group v-model="checkList">
          <el-checkbox :label="item.title" v-for='(item,index) in list' :disabled="disable(item.fieldKey)"
                       :key='index'/>
        </el-checkbox-group>
      </div>
      <template #footer>
          <el-button @click="closeDia">取消</el-button>
          <el-button class="main" type="primary" @click="handleOk">
            保存
          </el-button>
      </template>
    </el-dialog>
</template>


<script>
import {ref, watch} from "vue";
import {cloneDeep} from 'lodash';

export default {
  props: {
    list:Array,
    show:Boolean
  },
  setup(props,{emit}) {
    const fieldsCheck=ref(null)
    const showDia = ref(props.show)
    const menuList = ref(props.list)
    const checkList = ref([])
    const oldList = ref([])
    const handleOk = ()=>{
      let list = []
      for (let i = 0; i < checkList.value.length; i++) {
         list.push(menuList.value.find((item)=>item.title === checkList.value[i] ))
      }
     emit('handleOk',list)
     oldList.value = cloneDeep(checkList.value)
     showDia.value = false
    }
    const disable = (key) => {
      return key === 'productName' || key === 'img';
    }
    const checkItem = ()=>{
      let list = []
      menuList.value.forEach((item)=>{
        list.push(item.title)
      })
      list =Array.from(new Set(list))
      checkList.value = cloneDeep(list)
      oldList.value = cloneDeep(list)
    }
    const handleClickBtn = ()=>{
      showDia.value = !showDia.value
    }
    const closeDia = ()=>{
      showDia.value = false
      checkList.value = cloneDeep(oldList.value)
    }
    watch(
      () => props.list,
      (newValue, oldValue) =>{
      menuList.value = newValue
      checkItem()
    })
    return {
      closeDia,
      showDia,
      menuList,
      checkList,
      handleOk,
      fieldsCheck,
      handleClickBtn,
      oldList,
      disable
    };
  },
};
</script>
<style lang="scss" scoped>
  .checkModal{
    .fieldsContent{
      padding: 0 32px;
      .el-checkbox-group{
        .el-checkbox{
          margin-right: 60px;
          margin-bottom: 20px;
        }
      }
    }
  }
</style>
