<template>
  <Transition >
    <div class="wy-model-open cur_p">
      <span v-bind="attrs" class="model-open-text " @click="openModal">
        拆零
      </span>
      <know-more content="拆零：如采购商品，有拆开部分售卖且拆开的商品，不需要用RFID进行管理，则需要配置"></know-more>
    </div>
  </Transition>
  <el-dialog
    title="拆零"
    v-model="changeModal"
    width="640"
    class="model-open-dialog"
    :before-close="closeModal"
    :close-on-click-modal="false"
    :close-on-press-escape="false">
    <div class="content">
      <el-scrollbar height="300px">
     <div class="form dp_f">
        <el-select v-model="modelInfo.modelId"  placeholder="请选择产品型号" class="w160">
          <el-option
            v-for="item in modelList"
            :key="getModelId(item)"
            :label="item.modelName"
            :disabled="item.scattered || item.packing || item.discard"
            :value="getModelId(item)">
          </el-option>
        </el-select>
       <div class="unit">1 <OverFlowText :value="unit" max-length="5" class="span"></OverFlowText> =</div>
       <el-input  class="model-input mr_10" v-elInput v-model="modelInfo.scatteredCount" @blur="modelInfo.scatteredCount = $event.target.value" v-number placeholder="如：10"
                  maxlength="5"></el-input>
       <el-input class="model-input" v-elInput v-model="modelInfo.scatteredName" placeholder="如：件"
                 maxlength="5"></el-input>
       <el-button type="primary" class="form-btn" @click="addModel">添加</el-button>
     </div>
      <div class="model-open-list">
        <template v-for="item in isScatteredList" :key="getModelId(item)">
          <div class="item">
          <div class="model-item-name">
            规格/型号：{{item.modelName}}
          </div>
          <div class="model-item-unit">
            1{{' '+unit}}={{item.scatteredCount}}{{" "+ item.scatteredName}}
          </div>
            <img alt="位元灵感" src="@/assets/img/index/delete.png" class="delImg cur_p" v-if="item.orders === 0"
                 @click="delModal(item)"/>
        </div>
        </template>
      </div>
      </el-scrollbar>

    </div>
    <template #footer>
      <el-button @click="closeModal">取消</el-button>
      <el-button type="primary" :disabled="canSave" @click="handleConfirm">保存</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {computed, ref, toRefs, useAttrs, watch} from "vue";
import {cloneDeep, isEqual} from 'lodash'
import useProductModel from "@utils/tool/product/useProductModel";
import knowMore from "@views/shop/components/knowMore.vue";
import OverFlowText from "@components/overFlowText/index.vue";
import message from "@/utils/tool/message";

const { modelInfo , modelList ,isScatteredList ,resetModel,checkModel} = useProductModel()
const attrs = useAttrs()
const changeModal = ref(false)
const props = defineProps({
  model:{
    type: Array,
    default: ()=>[]
  },
  unit:{
    type: String,
    default: ''
  }
})
const { model,unit } = toRefs(props)
const emit = defineEmits(['update:model'])
//打开拆零弹窗
const openModal = ()=>{
  if(!model.value.length) return
  if(!unit.value){
    message.error('请先选择单位，再进行拆零操作')
    return
  }
  changeModal.value = true
}
//点击关闭拆零弹窗
const closeModal = ()=>{
  modelList.value = cloneDeep(model.value)
  resetModel()
  changeModal.value = false
}
//添加规格
const addModel = ()=>{
  if(!checkModel(unit.value)) return;
  for (const model of modelList.value) {
    if(modelInfo.modelId === getModelId(model)){
      model.scatteredCount = modelInfo.scatteredCount
      model.scatteredName = modelInfo.scatteredName
      model.scattered = true
      break
    }
  }
  resetModel()
}
//删除规格
const delModal = (item)=>{
  for (const model of modelList.value) {
    if(getModelId(model) === getModelId(item)){
      model.scatteredCount = -1
      model.scatteredName = ''
      model.scattered = false
      return
    }
  }
}
const getModelId = (i)=>{
  return  i.modelId ? i.modelId : i.modelName
}
//确认修改拆零
const handleConfirm = ()=>{
  emit('update:model',modelList.value)
  closeModal()
}

const canSave = computed(()=>{
  return model.value.findIndex(i => i.scattered) === -1 && modelList.value.findIndex(i => i.scattered) === -1;
})

//监听model变化
watch(model, (val) => {
  if(isEqual(val, modelList.value)) return
  modelList.value =cloneDeep(val)
},{ deep: true, immediate: true})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "modelOpenBtn",
});
</script>

<style lang="scss" src="./index.scss" scoped>
</style>
